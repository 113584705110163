import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { RejectInvoiceModal } from "./modal/RejectInvoiceModal";
import { APPROVE_REJECT_MODAL_TYPE } from "src/stores/krediflow/ApproveRejectInvoiceStore";

const RejectInvoiceBase = () => {
    const { approveRejectInvoiceStore, invoiceStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const displayRejectInvoiceModal = useCallback(() => {
        approveRejectInvoiceStore.setIsModalDisplayed(true);
        approveRejectInvoiceStore.setDisplayedModalType(APPROVE_REJECT_MODAL_TYPE.REJECT);
    }, [approveRejectInvoiceStore]);

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <UI.Button
                    label={t("screens.kredi_flow.action.reject")}
                    disabled={!invoiceStore.currentInvoice?.canCurrentUserApproveOrReject}
                    className={"mb-2 default-button-height"}
                    onClick={displayRejectInvoiceModal}
                />
                <RejectInvoiceModal />
            </div>
        </div>
    );
};

export const RejectInvoice = Sentry.withProfiler(observer(RejectInvoiceBase));
