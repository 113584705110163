import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { RejectInvoice } from "src/screens/creditors/creditor/details/actions/rejectInvoice/RejectInvoice";
import { ApproveInvoice } from "src/screens/creditors/creditor/details/actions/approveInvoice/ApproveInvoice";
import { StartWorkflow } from "src/screens/creditors/creditor/details/actions/startWorkflow/StartWorkflow";
import { LOADING_TYPE } from "src/stores/krediflow/InvoiceEnums";
import styles from "./InvoiceCard.module.css";

const InvoiceActionCardBase = () => {
    const { t } = useTranslation();
    const { invoiceStore } = useContext(RootStoreContext);

    return (
        <UI.Card title={t("screens.tickets.actions").toString()} flex>
            {invoiceStore.loadingType === LOADING_TYPE.INITIALIZING ? (
                <div className={styles.LoadingContainer}>
                    <UI.RotatingSpinner noLogo size={40} className={styles.LoadingTicketSpinner} />
                    <div className="mt-3">{t("screens.kredi_flow.loading_actions")}</div>
                </div>
            ) : (
                <>
                    {invoiceStore.currentInvoice?.canCurrentUserSeeStartWorkflowButton && <StartWorkflow />}
                    <ApproveInvoice />
                    <RejectInvoice />
                </>
            )}
        </UI.Card>
    );
};

export const InvoiceActionCard = Sentry.withProfiler(observer(InvoiceActionCardBase));
