import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ApproveInvoiceModal } from "./modal/ApproveInvoiceModal";
import { APPROVE_REJECT_MODAL_TYPE } from "src/stores/krediflow/ApproveRejectInvoiceStore";

const ApproveInvoiceBase = () => {
    const { approveRejectInvoiceStore, invoiceStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const displayApproveInvoiceModal = useCallback(() => {
        approveRejectInvoiceStore.setIsModalDisplayed(true);
        approveRejectInvoiceStore.setDisplayedModalType(APPROVE_REJECT_MODAL_TYPE.APPROVE);
    }, [approveRejectInvoiceStore]);

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <UI.Button
                    label={t("screens.kredi_flow.action.approve")}
                    disabled={!invoiceStore.currentInvoice?.canCurrentUserApproveOrReject}
                    className={"mb-2 default-button-height"}
                    onClick={displayApproveInvoiceModal}
                />
                <ApproveInvoiceModal />
            </div>
        </div>
    );
};

export const ApproveInvoice = Sentry.withProfiler(observer(ApproveInvoiceBase));
