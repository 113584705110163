import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import {
    activeRoleStates,
    IAccount,
    IAccounting,
    IAssignedUser,
    ICostCenter,
    ICreateInvoiceResponse,
    ICreditor,
    ICreditorInfo,
    IInvoiceFile,
    IInvoiceInfo,
    IInvoicePaymentInfo,
    IInvoiceProperties,
    IInvoiceType,
    IInvoiceUpsertVariables,
    IOrder,
    IPaymentAccount,
    IQRExtractInvoiceData,
    IRealestate,
    ITicket,
    IUnitData,
    IWorkflowUser
} from "./InvoiceTypes";
import {
    INVOICE_ACCOUNTING_FIELD_TYPE,
    INVOICE_FIELD_TYPE,
    INVOICE_FILE_STATE,
    INVOICE_ORIGIN,
    INVOICE_PROPERTY,
    INVOICE_STATE,
    INVOICE_TYPE,
    LOADING_TYPE,
    RUNNING_SUBSCRIPTION_TPYE,
    WORKFLOWUSER_ROLE
} from "./InvoiceEnums";
import { findDifferences } from "src/utils/Object";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import {
    GET_ALL_INVOICES,
    GET_INVOICE_BY_NUMBER,
    UPSERT_INVOICE,
    GET_INVOICE_NUMBER_BY_ID,
    SUBSCRIBE_TO_INVOICE_WORKFLOWUSER_UPDATES,
    SUBSCRIBE_TO_INVOICE_STATE_UPDATE
} from "src/api/cred";
import {
    GetAllInvoices,
    GetAllInvoices_cred_invoices,
    GetAllInvoices_cred_invoices_insertuserinfo,
    GetAllInvoices_cred_invoices_workflowusers
} from "src/api/generated/GetAllInvoices";
import { getInvoiceStateById } from "./InvoiceListStore";
import {
    GetInvoiceByNumber,
    GetInvoiceByNumberVariables,
    GetInvoiceByNumber_cred_invoices,
    GetInvoiceByNumber_cred_invoices_creditor,
    GetInvoiceByNumber_cred_invoices_insertuserinfo,
    GetInvoiceByNumber_cred_invoices_ticket,
    GetInvoiceByNumber_cred_invoices_workflowusers
} from "src/api/generated/GetInvoiceByNumber";
import { GetInvoiceNumberById, GetInvoiceNumberByIdVariables } from "src/api/generated/GetInvoiceNumberById";
import i18next from "i18next";
import { isEmpty, isObject } from "lodash";
import { getDateStringForFormElement, validateIBAN, validateInvoiceReferenceNumber } from "./Utils";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleId, getRoleKey, Role, ErpType } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";
import { Route } from "src/config/routes";
import { UpsertInvoice, UpsertInvoiceVariables } from "src/api/generated/UpsertInvoice";
import { DELETE_FILE } from "src/api/ticket";
import { DeleteFile, DeleteFileVariables } from "src/api/generated/DeleteFile";
import { Subscription } from "zen-observable-ts";
import {
    SubscribeToInvoiceWorkflowUserUpdates,
    SubscribeToInvoiceWorkflowUserUpdates_workflowusers,
    SubscribeToInvoiceWorkflowUserUpdatesVariables
} from "src/api/generated/SubscribeToInvoiceWorkflowUserUpdates";
import {
    SubscribeToInvoiceStateUpdate,
    SubscribeToInvoiceStateUpdate_invoice,
    SubscribeToInvoiceStateUpdateVariables
} from "src/api/generated/SubscribeToInvoiceStateUpdate";

const INVOICE_LIST_REFRESH_TIME_IN_SECONDS = 60;

const uuidRegex = {
    INVOICE_ID: /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
};

export class InvoiceStore {
    rootStore: RootStore;

    invoiceTypes: IInvoiceType[] = [
        {
            name: `${i18next.t(`screens.kredi_flow.invoice_type.${String(INVOICE_TYPE.BILL)}`)}`,
            type: INVOICE_TYPE.BILL
        },
        {
            name: `${i18next.t(`screens.kredi_flow.invoice_type.${String(INVOICE_TYPE.INVOICE)}`)}`,
            type: INVOICE_TYPE.INVOICE
        }
    ];

    invoices: Invoice[] = [];
    currentInvoice: Invoice | undefined;
    isEditing: boolean = false;
    isAccountingEditing: boolean = true;
    loadingType: LOADING_TYPE = LOADING_TYPE.NONE;
    runningSubscriptionType = RUNNING_SUBSCRIPTION_TPYE.NONE;

    loadAllInvoicesTimer?: NodeJS.Timer;
    initialLoadAllInvoicesCompleted: boolean = false;

    error?: {
        loadingInvoice: string;
    } = undefined;

    NEW_INVOICE_ID = "new-invoice";

    numberOfDisplayedItems: number = 100;
    sliceSizeOfDisplayedItems: number = 50;

    userDefinedAfterSaveFunction?: () => void;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            invoices: observable,
            initialLoadAllInvoicesCompleted: observable,
            error: observable,
            currentInvoice: observable,
            isEditing: observable,
            isAccountingEditing: observable,
            loadingType: observable,
            runningSubscriptionType: observable,
            numberOfDisplayedItems: observable,
            sliceSizeOfDisplayedItems: observable,
            userDefinedAfterSaveFunction: observable,

            loadAllInvoices: action,
            setCurrentInvoice: action,
            initialLoadAllInvoices: action,
            setInitialLoadAllInvoicesCompleted: action,
            setInvoices: action,
            init: action,
            initForm: action,
            setRealestateFormField: action,
            setUnitFormField: action,
            setTicketFormField: action,
            setOrderFormField: action,
            resetFormFields: action,
            setIsEditing: action,
            setIsAccountingEditing: action,
            setLoadingType: action,
            setRunningSubscriptionType: action,
            setNumberOfDisplayedItems: action,
            setSliceSizeOfDisplayedItems: action,
            resetCreditorFormField: action,
            resetRealestateFormField: action,
            resetUnitFormField: action,
            resetPaymentAccountFormField: action,
            resetPaymentAccountIdFormField: action,
            resetTicketFormField: action,
            resetOrderFormField: action,
            discardCurrentInvoiceChanges: action,
            setUserDefinedAfterSaveFunction: action,
            loadRealestateData: action,
            loadUnitData: action,
            updateInvoiceAndFormWithSelectedRealestate: action,
            updateInvoiceAndFormWithSelectedUnit: action,
            updateInvoiceAndFormWithSelectedTicket: action,
            updateInvoiceAndFormWithSelectedOrder: action,
            updateAccountingWithSelectedRealestate: action,
            updateInvoiceStoreWithSelectedCreditor: action,
            extractQrCodeDataFromPDF: action,
            mapExtractedDataOnCurrentInvoice: action,
            saveCurrentInvoice: action,
            startLoadAllInvoicesTimer: action
        });
    }

    init = async (invoiceNumber: string) => {
        this.setLoadingType(LOADING_TYPE.INITIALIZING);

        await this.rootStore.creditorSearchStore.init();
        await this.rootStore.ticketSearchStore.init();
        await this.rootStore.orderSearchStore.init();
        await this.rootStore.assignInvoiceStore.init();
        await this.rootStore.approveRejectInvoiceStore.init();
        await this.loadRealestateData();

        this.resetFormFields();

        const initiatedInvoice = await this.initForm(invoiceNumber);

        if (initiatedInvoice) {
            this.rootStore.paymentAccountSearchStore.init(initiatedInvoice);

            if (invoiceNumber !== this.NEW_INVOICE_ID) {
                this.currentInvoice?.startInvoiceWorkflowUsersSubscription();
                this.currentInvoice?.startInvoiceStateUpdateSubscription();
            }
        }

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    initForm = async (invoiceNumber: string): Promise<Invoice | undefined> => {
        // Invoice that was newly created or loaded
        let initiatedInvoice: Invoice | undefined = undefined;

        if (invoiceNumber === this.NEW_INVOICE_ID) {
            this.resetFormFields();
            this.setIsEditing(true);
            initiatedInvoice = new Invoice(this.rootStore);
            this.setCurrentInvoice(initiatedInvoice);
        } else {
            initiatedInvoice = await this.loadCurrentInvoice(invoiceNumber);

            if (initiatedInvoice) {
                this.setCurrentInvoice(initiatedInvoice);
                initiatedInvoice.resetAllFieldsWithChanges();
            }
        }

        return initiatedInvoice;
    };

    discardCurrentInvoiceChanges = async () => {
        const invoiceNumber =
            !this.currentInvoice?.isNewInvoice || this.currentInvoice?.number !== undefined
                ? String(this.currentInvoice?.number)
                : this.NEW_INVOICE_ID;

        this.resetFormFields();

        await this.initForm(invoiceNumber);
    };

    resetFormFields = () => {
        this.currentInvoice?.reset();

        this.resetCreditorFormField();
        this.resetPaymentAccountFormField();
        this.resetPaymentAccountIdFormField();
        this.resetRealestateFormField();
        this.resetUnitFormField();
        this.resetTicketFormField();
        this.resetOrderFormField();
    };

    resetCreditorFormField = () => {
        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.CREDITOR, undefined);
    };

    resetPaymentAccountFormField = () => {
        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.PAYMENT_ACCOUNT, undefined);
        this.rootStore.paymentAccountSearchStore.currentSelectedIBAN = "";
    };

    resetPaymentAccountIdFormField = () => {
        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.PAYMENT_ACCOUNT_ID, undefined);
    };

    resetTicketFormField = () => {
        const ticketSearchStore = this.rootStore.ticketSearchStore;
        ticketSearchStore.setCurrentTicketSearchQuery("");
        ticketSearchStore.setSelectedTicketQueryString("");

        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.TICKET, undefined);

        this.resetOrderFormField();
    };

    resetOrderFormField = () => {
        const orderSearchStore = this.rootStore.orderSearchStore;
        orderSearchStore.setCurrentOrderSearchQuery("");
        orderSearchStore.setSelectedOrderQueryString("");

        orderSearchStore.resetOrderSearchFilter();

        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.ORDER, undefined);
    };

    resetRealestateFormField = () => {
        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.REALESTATE, undefined);

        const realestateSearchStore = this.rootStore.realestateSearchStore;
        realestateSearchStore.setCurrentRealestateSearchQuery("");
        realestateSearchStore.setSelectedRealestateQueryString("");
        realestateSearchStore.setSelectedRealestateName("");

        this.resetUnitFormField();
    };

    resetUnitFormField = () => {
        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.UNIT, undefined);

        const unitSearchStore = this.rootStore.unitSearchStore;
        unitSearchStore.setCurrentUnitSearchQuery("");
        unitSearchStore.setSelectedUnitQueryString("");
    };

    startLoadAllInvoicesTimer = () => {
        this.loadAllInvoicesTimer = setInterval(() => {
            this.loadAllInvoices();
        }, INVOICE_LIST_REFRESH_TIME_IN_SECONDS * 1000);
    };

    clearLoadAllInvoicesTimer = () => {
        clearInterval(this.loadAllInvoicesTimer);
    };

    loadCurrentInvoice = async (invoiceNumber: string): Promise<Invoice | undefined> => {
        this.setIsEditing(false);

        let loadedInvoice: Invoice | undefined = undefined;

        /* FETCH INVOICE FROM SERVER */
        this.resetFormFields();
        this.error = undefined;

        const { data }: { data: GetInvoiceByNumber } = await apolloClientInstance.query<
            GetInvoiceByNumber,
            GetInvoiceByNumberVariables
        >({
            query: GET_INVOICE_BY_NUMBER,
            fetchPolicy: "no-cache",
            variables: {
                invoiceNumber: Number(invoiceNumber)
            }
        });

        if (data.cred_invoices.length === 0) {
            const errorMessage = i18next.t("screens.kredi_flow.invoice_details.error.invoice_not_found");
            this.error = {
                loadingInvoice: errorMessage
            };

            return undefined;
        }

        const newInvoiceData = await this.getInvoiceObjectFromGetInvoiceByNumberQuery(data.cred_invoices[0]);

        loadedInvoice = new Invoice(this.rootStore, newInvoiceData);

        this.initializeFormFields(loadedInvoice);

        return loadedInvoice;
    };

    loadAllInvoices = async () => {
        const { data }: { data: GetAllInvoices } = await apolloClientInstance.query<GetAllInvoices>({
            query: GET_ALL_INVOICES,
            fetchPolicy: "no-cache",
            variables: { userId: this.rootStore.authStore.user?.userid }
        });

        if (data) {
            const allInvoices: Invoice[] = data.cred_invoices.map((invoice) => {
                const newInvoiceData = this.getInvoiceObjectFromGetAllInvoicesQuery(invoice);
                return new Invoice(this.rootStore, newInvoiceData);
            });

            this.setInvoices(allInvoices);
        }
    };

    initialLoadAllInvoices = async () => {
        this.setInitialLoadAllInvoicesCompleted(false);

        const { data: dataFirstInvoicesTranch }: { data: GetAllInvoices } =
            await apolloClientInstance.query<GetAllInvoices>({
                query: GET_ALL_INVOICES,
                fetchPolicy: "no-cache",
                variables: { limit: 100 }
            });

        if (dataFirstInvoicesTranch) {
            const first100Invoices: Invoice[] = dataFirstInvoicesTranch.cred_invoices.map((invoice) => {
                const newInvoiceData = this.getInvoiceObjectFromGetAllInvoicesQuery(invoice);

                return new Invoice(this.rootStore, newInvoiceData);
            });

            this.setInvoices(first100Invoices);
        }

        const { data: dataRemainingInvoices }: { data: GetAllInvoices } =
            await apolloClientInstance.query<GetAllInvoices>({
                query: GET_ALL_INVOICES,
                fetchPolicy: "no-cache",
                variables: { limit: null, offset: 100 }
            });

        if (dataRemainingInvoices) {
            const remainingInvoices: Invoice[] = dataRemainingInvoices.cred_invoices.map((ticket) => {
                const newInvoiceData = this.getInvoiceObjectFromGetAllInvoicesQuery(ticket);
                return new Invoice(this.rootStore, newInvoiceData);
            });

            const allInvoices = this.invoices.concat(remainingInvoices);
            this.setInvoices(allInvoices);
        }

        this.setInitialLoadAllInvoicesCompleted(true);
    };

    loadRealestateData = async () => {
        await this.rootStore.realestateSearchStore.loadRealestateData();
    };

    loadUnitData = async (realestateId: string) => {
        await this.rootStore.unitSearchStore.loadUnitData(realestateId);
    };

    getInvoiceObjectFromGetAllInvoicesQuery = (invoice: GetAllInvoices_cred_invoices): IInvoiceProperties => {
        const { zip, city } =
            invoice.realestate && invoice.realestate.houses.length > 0
                ? { zip: invoice.realestate.houses[0].zip ?? "", city: invoice.realestate.houses[0].city ?? "" }
                : { zip: "", city: "" };

        const realestate: IRealestate | undefined = invoice.realestate
            ? {
                  id: invoice.realestate.id,
                  number: invoice.realestate.number,
                  name: invoice.realestate.name,
                  zip,
                  city
              }
            : undefined;

        const creditor: ICreditor | undefined = invoice.creditor
            ? {
                  id: invoice.creditor.id,
                  name: invoice.creditor.name,
                  personId: invoice.creditor.personid ?? undefined
              }
            : undefined;

        const state = getInvoiceStateById(invoice.state);

        let assignedUser: IAssignedUser | null = this.getAssignedUserByInvoiceState(
            state,
            invoice.insertuserinfo,
            invoice.workflowusers
        );

        const newInvoiceData: IInvoiceProperties = {
            id: invoice.id,
            invoiceType: invoice.type as INVOICE_TYPE,
            number: invoice.number,
            state: getInvoiceStateById(invoice.state),
            invoiceNumber: invoice.invoicenumber ?? undefined,
            amount: invoice.amount ?? undefined,
            date: invoice.date,
            dueDate: invoice.duedate,
            paidDate: invoice.date,
            realestate: realestate,
            assignedUser: assignedUser ?? undefined,
            creditor: creditor
        };

        return newInvoiceData;
    };

    getInvoiceObjectFromGetInvoiceByNumberQuery = async (
        invoice: GetInvoiceByNumber_cred_invoices
    ): Promise<IInvoiceProperties> => {
        const { zip, city } =
            invoice.realestate && invoice.realestate.houses.length > 0
                ? { zip: invoice.realestate.houses[0].zip ?? "", city: invoice.realestate.houses[0].city ?? "" }
                : { zip: "", city: "" };

        const realestate: IRealestate | undefined = invoice.realestate
            ? {
                  id: invoice.realestate.id,
                  number: invoice.realestate.number,
                  name: invoice.realestate.name,
                  zip,
                  city
              }
            : undefined;

        const unit: IUnitData | undefined = invoice.unit
            ? {
                  houseId: invoice.unit.houseid,
                  unitId: invoice.unit.id,
                  unitNumber: invoice.unit.number ?? undefined,
                  unitName: invoice.unit.name ?? "",
                  tenancyData: undefined
              }
            : undefined;

        const creditor = this.getCreditorObjectFromGetInvoiceByNumberQueryCreditorResult(invoice.creditor);

        const paymentInfo: IInvoicePaymentInfo | undefined =
            this.getPaymentInfoObjectFromGetInvoiceByNumberQueryCreditorResult(invoice.paymentinfo);

        const ticket = invoice.ticket
            ? this.getTicketInfoObjectFromGetInvoiceByNumberQueryCreditorResult(invoice.ticket)
            : undefined;

        const order: IOrder | undefined = invoice.order
            ? {
                  id: invoice.order.orderid ?? undefined,
                  number: invoice.order.number ?? undefined,
                  date: invoice.order.date ?? undefined,
                  date_long: invoice.order.date_long ?? undefined,
                  contractorId: invoice.order.contractorid ?? undefined,
                  title: invoice.order.title ?? undefined,
                  instruction: invoice.order.instruction ?? undefined,
                  contractorName1: invoice.order.name1 ?? undefined,
                  contractorName2: invoice.order.name2 ?? undefined,
                  ticket: invoice.order.ticket
                      ? this.getTicketInfoObjectFromGetInvoiceByNumberQueryCreditorResult(invoice.order.ticket)
                      : undefined,
                  contractorType: invoice.order.contractortype ?? undefined
              }
            : undefined;

        const file: IInvoiceFile | undefined =
            invoice.invoicefiles.length > 0
                ? {
                      fileId: invoice.invoicefiles[0].fileid,
                      invoiceFileId: invoice.invoicefiles[0].invoicefileid,
                      state: INVOICE_FILE_STATE.LOADED_FROM_DB
                  }
                : undefined;

        if (file && file.fileId) {
            const downloadedInvoiceFile = await this.downloadInvoiceFile(file.fileId);

            if (downloadedInvoiceFile) {
                let fileBlob = await downloadedInvoiceFile.blob();
                const objUrl = window.URL.createObjectURL(fileBlob);

                file.url = objUrl;
            }
        }

        const workflowUsers: IWorkflowUser[] = invoice.workflowusers.map((workflowUser) => {
            return {
                role: workflowUser.role,
                id: workflowUser.id,
                signaturestamp: workflowUser.signaturestamp,
                user: {
                    id: workflowUser.user.id,
                    name1: workflowUser.user.name1 ?? "",
                    name2: workflowUser.user.name2 ?? ""
                }
            };
        });

        const newInvoiceData: IInvoiceProperties = {
            id: invoice.id,
            invoiceType: invoice.type as INVOICE_TYPE,
            number: invoice.number,
            state: getInvoiceStateById(invoice.state),
            invoiceNumber: invoice.invoicenumber ?? undefined,
            amount: invoice.amount ?? undefined,
            date: invoice.date,
            dueDate: invoice.duedate,
            paidDate: invoice.paiddate,
            realestate: realestate,
            unit: unit,
            creditor: creditor,
            paymentaccountid: invoice.paymentaccountid ?? undefined,
            paymentAccount: invoice.paymentaccount ?? undefined,
            payoutaccountid: invoice.payoutaccountid ?? undefined,
            paymentInfo: paymentInfo,
            ticket: ticket,
            order: order,
            invoiceFile: file,
            workflowUsers: workflowUsers,
            workflowInstance: invoice.workflowinstance ?? undefined
        };

        return newInvoiceData;
    };

    downloadInvoiceFile = async (fileId: string): Promise<any | undefined> => {
        let downloadedInvoiceFile = undefined;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const downloadInvoiceFileResponse = await fetch(`${NetworkConfig.downloadFileVersion2}/${fileId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (downloadInvoiceFileResponse.status === 200) {
                downloadedInvoiceFile = downloadInvoiceFileResponse;
            } else {
                console.error("Error occured while trying to download the invoice file: ", downloadInvoiceFileResponse);
            }
        } catch (error) {
            console.error("Error saving invoice: ", error);
        }

        return downloadedInvoiceFile;
    };

    getTicketInfoObjectFromGetInvoiceByNumberQueryCreditorResult = (
        ticketResultFromQuery: GetInvoiceByNumber_cred_invoices_ticket
    ): ITicket | undefined => {
        const ticket: ITicket | undefined = ticketResultFromQuery
            ? {
                  id: ticketResultFromQuery.incidentid ?? undefined,
                  number: ticketResultFromQuery.number ?? undefined,
                  title: ticketResultFromQuery.title ?? undefined,
                  date: ticketResultFromQuery.date ?? undefined,
                  date_long: ticketResultFromQuery.date_long ?? undefined,
                  realestateid: ticketResultFromQuery.realestateid ?? undefined,
                  unitid: ticketResultFromQuery.unitid ?? undefined
              }
            : undefined;

        return ticket;
    };

    getPaymentInfoObjectFromGetInvoiceByNumberQueryCreditorResult = (
        invoicePaymentInfo: any
    ): IInvoicePaymentInfo | undefined => {
        let paymentInfo: IInvoicePaymentInfo | undefined = undefined;

        if (invoicePaymentInfo && isObject(invoicePaymentInfo) && !isEmpty(invoicePaymentInfo)) {
            paymentInfo = {};

            if ("iban" in invoicePaymentInfo && typeof invoicePaymentInfo.iban === "string") {
                paymentInfo.iban = invoicePaymentInfo.iban;
            }

            if ("type" in invoicePaymentInfo && typeof invoicePaymentInfo.type === "string") {
                paymentInfo.type = invoicePaymentInfo.type;
            }

            if ("reference" in invoicePaymentInfo && typeof invoicePaymentInfo.reference === "string") {
                paymentInfo.reference = invoicePaymentInfo.reference;
            }
        }

        return paymentInfo;
    };

    getCreditorObjectFromGetInvoiceByNumberQueryCreditorResult = (
        creditorResultFromQuery: GetInvoiceByNumber_cred_invoices_creditor | null
    ): ICreditor | undefined => {
        const creditor: ICreditor | undefined = creditorResultFromQuery
            ? {
                  id: creditorResultFromQuery.creditorid,
                  name: creditorResultFromQuery.creditorname ?? "",
                  personId: creditorResultFromQuery.personid ?? undefined,
                  name1: creditorResultFromQuery.name1 ?? undefined,
                  name2: creditorResultFromQuery.name2 ?? undefined,
                  mobile: creditorResultFromQuery.mobile ?? undefined,
                  phonebusiness: creditorResultFromQuery.phonebusiness ?? undefined,
                  email: creditorResultFromQuery.email ?? undefined,
                  gender: creditorResultFromQuery.gender ?? undefined,
                  origin: creditorResultFromQuery.person_origin ?? undefined,
                  street: creditorResultFromQuery.street ?? undefined,
                  housenumber: creditorResultFromQuery.housenumber ?? undefined,
                  zip: creditorResultFromQuery.zip ?? undefined,
                  city: creditorResultFromQuery.city ?? undefined,
                  paymentAccounts: creditorResultFromQuery.paymentaccounts ?? undefined
              }
            : undefined;

        return creditor;
    };

    getAssignedUserByInvoiceState = (
        invoiceState: INVOICE_STATE,
        insertUserInfo:
            | GetAllInvoices_cred_invoices_insertuserinfo
            | GetInvoiceByNumber_cred_invoices_insertuserinfo
            | null,
        workflowUsers: GetAllInvoices_cred_invoices_workflowusers[] | GetInvoiceByNumber_cred_invoices_workflowusers[]
    ) => {
        let assignedUser: IAssignedUser | null = null;

        switch (invoiceState) {
            case INVOICE_STATE.NEW:
            case INVOICE_STATE.READY_FOR_ACCOUNTING:
                assignedUser = insertUserInfo ?? null;
                break;
            case INVOICE_STATE.READY_FOR_VISA:
                assignedUser =
                    workflowUsers.find((workflowUser) => workflowUser.role === Number(WORKFLOWUSER_ROLE.VISA_1))
                        ?.user ?? null;
                break;
            case INVOICE_STATE.VISA_1_OK:
                assignedUser =
                    workflowUsers.find((workflowUser) => workflowUser.role === Number(WORKFLOWUSER_ROLE.VISA_2))
                        ?.user ?? null;
                break;
            case INVOICE_STATE.VISA_2_OK:
                assignedUser =
                    workflowUsers.find((workflowUser) => workflowUser.role === Number(WORKFLOWUSER_ROLE.VISA_3))
                        ?.user ?? null;
                break;
            case INVOICE_STATE.FULLY_SIGHTED:
            case INVOICE_STATE.SENT_TO_ERP:
                assignedUser =
                    workflowUsers.find((workflowUser) => workflowUser.role === Number(WORKFLOWUSER_ROLE.ACCOUNTANT))
                        ?.user ?? null;
                break;
            case INVOICE_STATE.REJECTED:
                assignedUser =
                    workflowUsers.find((workflowUser) => workflowUser.role === Number(WORKFLOWUSER_ROLE.REJECTED_TO))
                        ?.user ?? null;
                break;
            case INVOICE_STATE.CANCELLED:
                assignedUser =
                    workflowUsers.find((workflowUser) => workflowUser.role === Number(WORKFLOWUSER_ROLE.CANCELLED_BY))
                        ?.user ?? null;
                break;
        }

        return assignedUser;
    };

    initializeFormFields = async (invoice: Invoice) => {
        await this.setRealestateFormField(invoice);
        await this.setUnitFormField(invoice);
        await this.setTicketFormField(invoice);
        await this.setOrderFormField(invoice);
    };

    updateInvoiceAndFormWithSelectedRealestate = async (
        selectedRealestate: IRealestate,
        setTriggerFocusOnUnitInput: boolean = true
    ) => {
        const realestateSearchStore = this.rootStore.realestateSearchStore;

        const newRealestateFieldValue = realestateSearchStore.getFormattedRealestateFieldValue(selectedRealestate);

        realestateSearchStore.setCurrentRealestateSearchQuery(newRealestateFieldValue);
        realestateSearchStore.setSelectedRealestateQueryString(newRealestateFieldValue);
        realestateSearchStore.setSelectedRealestateName(selectedRealestate.name);

        this.loadUnitData(selectedRealestate.id);

        if (this.currentInvoice) {
            this.currentInvoice.updateProperty(INVOICE_PROPERTY.REALESTATE, selectedRealestate);
            await this.loadRealestateThumbnailFile(selectedRealestate.id);

            if (this.currentInvoice.isUnitSelected) {
                this.resetUnitFormField();
            }

            if (
                this.currentInvoice.isTicketSelected &&
                this.currentInvoice.ticket?.realestateid !== selectedRealestate.id
            ) {
                this.resetTicketFormField();
            }
        }

        this.rootStore.unitSearchStore.setTriggerFocusOnUnitInput(setTriggerFocusOnUnitInput);
    };

    updateAccountingWithSelectedRealestate = async (selectedRealestate: IRealestate, accountingRowIndex?: number) => {
        const accountingRealestateSearchStore = this.rootStore.accountingRealestateSearchStore;

        const newRealestateFieldValue =
            accountingRealestateSearchStore.getFormattedRealestateFieldValue(selectedRealestate);

        accountingRealestateSearchStore.setCurrentRealestateSearchQuery(newRealestateFieldValue, accountingRowIndex);
        accountingRealestateSearchStore.setSelectedRealestateQueryString(newRealestateFieldValue, accountingRowIndex);
        accountingRealestateSearchStore.setSelectedRealestateName(selectedRealestate.name, accountingRowIndex);

        if (this.currentInvoice) {
            this.currentInvoice.setAccountingRealestate(selectedRealestate, accountingRowIndex);
        }

        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            accountingRealestateSearchStore.setTriggerFocusNextAccountingAccountInput(true);
        } else if (erpType === ErpType.RIMO) {
            accountingRealestateSearchStore.setTriggerFocusNextAccountingAccountInput(true, accountingRowIndex);
        }
    };

    updateInvoiceAndFormWithSelectedUnit = async (selectedUnit: IUnitData) => {
        const unitSearchStore = this.rootStore.unitSearchStore;

        const newUnitFieldValue = unitSearchStore.getFormattedUnitName(selectedUnit);

        unitSearchStore.setCurrentUnitSearchQuery(newUnitFieldValue);
        unitSearchStore.setSelectedUnitQueryString(newUnitFieldValue);

        if (this.currentInvoice) {
            this.currentInvoice.updateProperty(INVOICE_PROPERTY.UNIT, selectedUnit);
        }
    };

    updateInvoiceAndFormWithSelectedTicket = async (selectedTicket: ITicket, updateOrderField: boolean = true) => {
        const newTicketFieldValue = this.rootStore.ticketSearchStore.getFormattedTicketFieldValue(selectedTicket);

        this.rootStore.ticketSearchStore.setCurrentTicketSearchQuery(newTicketFieldValue);
        this.rootStore.ticketSearchStore.setSelectedTicketQueryString(newTicketFieldValue);

        this.rootStore.orderSearchStore.setOrderSearchFilterTicket(true);

        const wasTicketChanged = this.currentInvoice?.ticket?.id !== selectedTicket.id;

        if (this.currentInvoice) {
            this.currentInvoice.updateProperty(INVOICE_PROPERTY.TICKET, selectedTicket);

            // Set the realestate in the form to the realestate of the selected ticket
            if (selectedTicket.realestateid) {
                const wasRealestateChanged = selectedTicket.realestateid !== this.currentInvoice.realestate?.id;

                if (wasRealestateChanged) {
                    const realestate = this.rootStore.realestateSearchStore.realestateData.find(
                        (realestate) => realestate.id === selectedTicket.realestateid
                    );

                    if (realestate) {
                        await this.updateInvoiceAndFormWithSelectedRealestate(realestate, false);
                    }
                }

                // Set the unit in the form to the unit of the selected ticket
                if (
                    selectedTicket.realestateid &&
                    selectedTicket.unitid &&
                    selectedTicket.unitid !== this.currentInvoice.unit?.unitId
                ) {
                    if (wasRealestateChanged) {
                        await this.rootStore.unitSearchStore.loadUnitData(selectedTicket.realestateid);
                    }

                    const unit = this.rootStore.unitSearchStore.unitData.find(
                        (unit) => unit.unitId === selectedTicket.unitid
                    );

                    if (unit) {
                        this.updateInvoiceAndFormWithSelectedUnit(unit);
                    }
                } else if (!selectedTicket.unitid) {
                    this.resetUnitFormField();
                }
            } else {
                this.resetRealestateFormField();
            }

            if (this.currentInvoice.order && wasTicketChanged && updateOrderField) {
                this.resetOrderFormField();
            }
        }
    };

    updateInvoiceAndFormWithSelectedOrder = async (selectedOrder: IOrder) => {
        const newOrderFieldValue = this.rootStore.orderSearchStore.getFormattedOrderFieldValue(selectedOrder);

        this.rootStore.orderSearchStore.setCurrentOrderSearchQuery(newOrderFieldValue);
        this.rootStore.orderSearchStore.setSelectedOrderQueryString(newOrderFieldValue);

        if (this.currentInvoice) {
            this.currentInvoice.updateProperty(INVOICE_PROPERTY.ORDER, selectedOrder);

            if (selectedOrder.ticket) {
                this.updateInvoiceAndFormWithSelectedTicket(selectedOrder.ticket, false);
            }
        }
    };

    updateInvoiceStoreWithSelectedCreditor = async (creditor: ICreditor | undefined) => {
        if (creditor) {
            this.rootStore.creditorSearchStore.setCurrentCreditorSearchQuery("");
            if (this.currentInvoice) {
                this.resetCreditorFormField();
                this.currentInvoice.updateProperty(INVOICE_PROPERTY.CREDITOR, creditor);
            }

            this.rootStore.paymentAccountSearchStore.setPaymentAccountData(creditor.paymentAccounts ?? []);
        }
    };

    setRealestateFormField = async (invoice: Invoice) => {
        const realestate = invoice.realestate;

        if (realestate) {
            await this.loadUnitData(realestate.id);

            const realestateSearchStore = this.rootStore.realestateSearchStore;

            await this.loadRealestateThumbnailFile(realestate.id);

            const newRealestateFieldValue = realestateSearchStore.getFormattedRealestateFieldValue(realestate);
            realestateSearchStore.setCurrentRealestateSearchQuery(newRealestateFieldValue);

            realestateSearchStore.setSelectedRealestateQueryString(newRealestateFieldValue);
            realestateSearchStore.setSelectedRealestateName(realestate.name);
        }
    };

    setUnitFormField = async (invoice: Invoice) => {
        const unit = invoice.unit;

        if (unit) {
            const unitSearchStore = this.rootStore.unitSearchStore;

            const newUnitFieldValue = unitSearchStore.getFormattedUnitName(unit);

            unitSearchStore.setCurrentUnitSearchQuery(newUnitFieldValue);
            unitSearchStore.setSelectedUnitQueryString(newUnitFieldValue);
        }
    };

    setTicketFormField = async (invoice: Invoice) => {
        const ticketSearchStore = this.rootStore.ticketSearchStore;

        const ticket = invoice.ticket;

        if (ticket) {
            const newTicketFieldValue = ticketSearchStore.getFormattedTicketFieldValue(ticket);

            ticketSearchStore.setCurrentTicketSearchQuery(newTicketFieldValue);
            ticketSearchStore.setSelectedTicketQueryString(newTicketFieldValue);
        }

        if (invoice.realestate) {
            ticketSearchStore.setTicketSearchFilterRealestate(true);
        }
    };

    setOrderFormField = async (invoice: Invoice) => {
        const orderSearchStore = this.rootStore.orderSearchStore;

        const order = invoice.order;

        if (order) {
            const newOrderFieldValue = orderSearchStore.getFormattedOrderFieldValue(order);
            orderSearchStore.setCurrentOrderSearchQuery(newOrderFieldValue);
            orderSearchStore.setSelectedOrderQueryString(newOrderFieldValue);
        }

        if (invoice.isCreditorSelected) {
            orderSearchStore.setOrderSearchFilterCreditor(true);
            orderSearchStore.setOrderSearchFilterRealestate(false);
            orderSearchStore.setOrderSearchFilterTicket(false);
        }

        if (invoice.isRealestateSelected) {
            orderSearchStore.setOrderSearchFilterRealestate(true);
            orderSearchStore.setOrderSearchFilterCreditor(false);
            orderSearchStore.setOrderSearchFilterTicket(false);
        }

        if (invoice.isTicketSelected) {
            orderSearchStore.setOrderSearchFilterTicket(true);
            orderSearchStore.setOrderSearchFilterCreditor(false);
            orderSearchStore.setOrderSearchFilterRealestate(false);
        }
    };

    loadRealestateThumbnailFile = async (realestateId: string) => {
        const realestateThumbnailFileId = await this.rootStore.realestateSearchStore.getRealestateThumbnailFileId(
            realestateId
        );

        this.currentInvoice?.updateProperty(INVOICE_PROPERTY.REALESTATE_THUMBNAIL_FILEID, realestateThumbnailFileId);

        return realestateThumbnailFileId;
    };

    extractQrCodeDataFromPDF = async (pdfBase64String: string): Promise<IQRExtractInvoiceData | undefined> => {
        let qrExtractInvoiceData: IQRExtractInvoiceData | undefined = undefined;

        const requestBody = {
            invoicepdf: pdfBase64String
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.extractDataSinglePdfUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                const result = await response.json();

                const qrExtractInvoiceDataResponse: IQRExtractInvoiceData[] = result.data;

                if (qrExtractInvoiceDataResponse.length > 0) {
                    // Display info that more than 1 qr was detected and that information from first qr code was taken
                    if (qrExtractInvoiceDataResponse.length > 1) {
                        this.rootStore.uiStore.printStatusMessage(
                            `${i18next.t("screens.kredi_flow.mapping.more_than_one_invoice_found_info")}`,
                            MessageType.INFO
                        );
                    }

                    qrExtractInvoiceData = qrExtractInvoiceDataResponse[0];
                }
            } else {
                console.error("Error extracting data from the pdf file: ", response);
            }
        } catch (error) {
            console.error("Error extracting data from the pdf file: ", error);
        }

        return qrExtractInvoiceData;
    };

    mapExtractedDataOnCurrentInvoice = (extractedData: IQRExtractInvoiceData, overrideData: boolean) => {
        if (this.currentInvoice) {
            const creditorIdOverrideValue = this.decideOverrideValue(
                this.currentInvoice.creditor?.id,
                extractedData.creditorid,
                overrideData
            );

            if (creditorIdOverrideValue) {
                const creditorFromCustomer = this.rootStore.creditorSearchStore.creditorData.find((creditor) => {
                    return creditor.id === creditorIdOverrideValue;
                });

                if (creditorFromCustomer) {
                    this.updateInvoiceStoreWithSelectedCreditor(creditorFromCustomer);
                } else {
                    this.currentInvoice.creditor = undefined;
                }
            } else {
                this.currentInvoice.creditor = undefined;
            }

            const paymentAccountIdOverrideValue = this.decideOverrideValue(
                this.currentInvoice.paymentAccount?.id,
                extractedData.paymentaccountid,
                overrideData
            );

            if (paymentAccountIdOverrideValue) {
                const paymentAccountFromCustomer = this.rootStore.paymentAccountSearchStore.paymentAccountData.find(
                    (paymentAccount) => {
                        return paymentAccount.id === paymentAccountIdOverrideValue;
                    }
                );

                if (paymentAccountFromCustomer) {
                    this.rootStore.paymentAccountSearchStore.updateInvoiceStoreWithSelectedPaymentAccount(
                        paymentAccountFromCustomer
                    );
                } else {
                    this.currentInvoice.paymentAccount = undefined;
                }
            } else {
                this.currentInvoice.paymentAccount = undefined;
            }

            this.currentInvoice.amount = this.decideOverrideValue(
                this.currentInvoice.amount,
                extractedData.amount,
                overrideData
            );

            this.currentInvoice.invoiceNumber = this.decideOverrideValue(
                this.currentInvoice.invoiceNumber,
                extractedData.invoicenumber ?? undefined,
                overrideData
            );

            const dateOverrideValue = this.decideOverrideValue(
                this.currentInvoice.date,
                extractedData.date ? getDateStringForFormElement(extractedData.date) : undefined,
                overrideData
            );
            this.currentInvoice.date = dateOverrideValue ? getDateStringForFormElement(dateOverrideValue) : undefined;

            const paidDateOverrideValue = this.decideOverrideValue(
                this.currentInvoice.paidDate,
                extractedData.paiddate ? getDateStringForFormElement(extractedData.paiddate) : undefined,
                overrideData
            );
            this.currentInvoice.paidDate = paidDateOverrideValue
                ? getDateStringForFormElement(paidDateOverrideValue)
                : undefined;

            const dueDateOverrideValue = this.decideOverrideValue(
                this.currentInvoice.dueDate,
                extractedData.duedate ? getDateStringForFormElement(extractedData.duedate) : undefined,
                overrideData
            );
            this.currentInvoice.dueDate = dueDateOverrideValue
                ? getDateStringForFormElement(dueDateOverrideValue)
                : undefined;

            if (isObject(extractedData.paymentinfo) && !isEmpty(extractedData.paymentinfo)) {
                const referenceOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.paymentInfo?.reference,
                    extractedData.paymentinfo.reference,
                    overrideData
                );

                let ibanOverrideValue = undefined;

                if (!extractedData.paymentaccountid && !this.currentInvoice.paymentAccount?.iban) {
                    ibanOverrideValue = this.decideOverrideValue(
                        this.currentInvoice.paymentInfo?.iban,
                        extractedData.paymentinfo.iban,
                        overrideData
                    );

                    this.rootStore.paymentAccountSearchStore.setCurrentSelectedIBAN(ibanOverrideValue ?? "");
                }

                const typeOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.paymentInfo?.type,
                    extractedData.paymentinfo.type,
                    overrideData
                );

                this.currentInvoice.paymentInfo = {
                    reference: referenceOverrideValue,
                    iban: ibanOverrideValue,
                    type: typeOverrideValue
                };
            }

            if (
                isObject(extractedData.creditorinfo) &&
                !isEmpty(extractedData.creditorinfo) &&
                !extractedData.creditorid
            ) {
                const nameOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.creditorInfo?.name,
                    extractedData.creditorinfo.name,
                    overrideData
                );

                const streetOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.creditorInfo?.street,
                    extractedData.creditorinfo.street,
                    overrideData
                );

                const housenumberOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.creditorInfo?.housenumber,
                    extractedData.creditorinfo.housenumber,
                    overrideData
                );

                const zipOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.creditorInfo?.zip,
                    extractedData.creditorinfo.zip,
                    overrideData
                );

                const cityOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.creditorInfo?.city,
                    extractedData.creditorinfo.city,
                    overrideData
                );

                const countryOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.creditorInfo?.country,
                    extractedData.creditorinfo.country,
                    overrideData
                );

                this.currentInvoice.creditorInfo = {
                    name: nameOverrideValue,
                    street: streetOverrideValue,
                    housenumber: housenumberOverrideValue,
                    zip: zipOverrideValue,
                    city: cityOverrideValue,
                    country: countryOverrideValue
                };
            }

            if (isObject(extractedData.invoiceinfo) && !isEmpty(extractedData.invoiceinfo)) {
                const vatDateOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.invoiceInfo?.vatdate,
                    extractedData.invoiceinfo.vatdate,
                    overrideData
                );

                const vatRateOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.invoiceInfo?.vatrate,
                    extractedData.invoiceinfo.vatrate,
                    overrideData
                );

                const conditionsOverrideValue = this.decideOverrideValue(
                    this.currentInvoice.invoiceInfo?.conditions,
                    extractedData.invoiceinfo.conditions,
                    overrideData
                );

                this.currentInvoice.invoiceInfo = {
                    vatdate: vatDateOverrideValue ? getDateStringForFormElement(vatDateOverrideValue) : undefined,
                    vatrate: vatRateOverrideValue,
                    conditions: conditionsOverrideValue
                };
            }

            const realestateIdOverrideValue = this.decideOverrideValue(
                this.currentInvoice.realestate?.id,
                extractedData.realestateid,
                overrideData
            );

            if (realestateIdOverrideValue) {
                const realestateFromCustomer = this.rootStore.realestateSearchStore.realestateData.find(
                    (realestate) => {
                        return realestate.id === realestateIdOverrideValue;
                    }
                );

                if (realestateFromCustomer) {
                    this.updateInvoiceAndFormWithSelectedRealestate(realestateFromCustomer);
                } else {
                    this.resetRealestateFormField();
                }
            } else {
                this.resetRealestateFormField();
            }

            const unitIdOverrideValue = this.decideOverrideValue(
                this.currentInvoice.unit?.unitId,
                extractedData.unitid,
                overrideData
            );

            if (unitIdOverrideValue) {
                const unitFromCustomer = this.rootStore.unitSearchStore.unitData.find((unit) => {
                    return unit.unitId === unitIdOverrideValue;
                });

                if (unitFromCustomer) {
                    this.updateInvoiceAndFormWithSelectedUnit(unitFromCustomer);
                } else {
                    this.resetUnitFormField();
                }
            } else {
                this.resetUnitFormField();
            }

            const ticketIdOverrideValue = this.decideOverrideValue(
                this.currentInvoice.ticket?.id,
                extractedData.incidentid,
                overrideData
            );

            if (ticketIdOverrideValue) {
                const ticketFromCustomer = this.rootStore.ticketSearchStore.ticketData.find((ticket) => {
                    return ticket.id === ticketIdOverrideValue;
                });

                if (ticketFromCustomer) {
                    this.updateInvoiceAndFormWithSelectedTicket(ticketFromCustomer);
                } else {
                    this.resetTicketFormField();
                }
            } else {
                this.resetTicketFormField();
            }

            const orderIdOverrideValue = this.decideOverrideValue(
                this.currentInvoice.order?.id,
                extractedData.orderid,
                overrideData
            );

            if (orderIdOverrideValue) {
                const orderFromCustomer = this.rootStore.orderSearchStore.orderData.find((order) => {
                    return order.id === orderIdOverrideValue;
                });

                if (orderFromCustomer) {
                    this.updateInvoiceAndFormWithSelectedOrder(orderFromCustomer);
                } else {
                    this.resetOrderFormField();
                }
            } else {
                this.resetOrderFormField();
            }
        }
    };

    decideOverrideValue = <T>(
        currentValue: T | undefined,
        newValue: T | undefined,
        override: boolean
    ): T | undefined => {
        const currentHasValue =
            typeof currentValue === "number"
                ? currentValue !== undefined && currentValue !== 0
                : typeof currentValue === "string"
                ? currentValue !== undefined && currentValue !== ""
                : currentValue !== undefined && currentValue !== null;

        const newHasValue =
            typeof newValue === "number"
                ? newValue !== undefined && newValue !== 0
                : typeof newValue === "string"
                ? newValue !== undefined && newValue !== ""
                : newValue !== undefined && newValue !== null;

        if (override) {
            // Override is true, prefer newValue if it exists, otherwise keep the currentValue
            return newHasValue ? newValue : currentValue;
        } else {
            // Override is false, only set newValue if currentValue is not set and newValue exists
            return !currentHasValue && newHasValue ? newValue : currentValue;
        }
    };

    isinvoiceId = (invoiceid: string): boolean => {
        let isValid = false;
        if (invoiceid) {
            isValid = true;
        } else {
            isValid = false;
        }

        if (isValid) {
            if (uuidRegex.INVOICE_ID.test(invoiceid)) {
                isValid = true;
            } else {
                isValid = false;
            }
        }
        return isValid;
    };

    reRoutePathToInvoiceNumber = async (invoiceid: string) => {
        const { data }: { data: GetInvoiceNumberById } = await apolloClientInstance.query<
            GetInvoiceNumberById,
            GetInvoiceNumberByIdVariables
        >({
            query: GET_INVOICE_NUMBER_BY_ID,
            fetchPolicy: "no-cache",
            variables: {
                id: invoiceid
            }
        });

        if (data.cred_invoices.length === 0) {
            const errorMessage = i18next.t("screens.kredi_flow.invoice_details.error.invoice_not_found");
            this.error = {
                loadingInvoice: errorMessage
            };
        } else {
            const number = data.cred_invoices[0].number;
            window.location.href = `${Route.manager}${Route.creditor}/${number.toString()}`;
        }
    };

    saveCurrentInvoice = async (closeEditModeAfterSave: boolean = true): Promise<boolean> => {
        this.setLoadingType(LOADING_TYPE.SAVING_INVOICE);
        let hasSuccessfullySavedCurrentInvoice = false;
        let savingNewInvoice = this.currentInvoice?.isNewInvoice ? true : false;

        if (this.currentInvoice?.hasChanges) {
            hasSuccessfullySavedCurrentInvoice = await this.currentInvoice?.save();

            if (hasSuccessfullySavedCurrentInvoice) {
                if (closeEditModeAfterSave) {
                    this.setIsEditing(false);
                }

                if (savingNewInvoice) {
                    window.history.replaceState(
                        null,
                        "",
                        `${Route.manager}${Route.creditor}/${this.currentInvoice?.number}`
                    );
                }

                if (this.userDefinedAfterSaveFunction) {
                    this.userDefinedAfterSaveFunction();
                }

                this.rootStore.uiStore.printStatusMessage(
                    i18next.t("screens.kredi_flow.action.save.success"),
                    MessageType.SUCCESS
                );
            } else {
                this.rootStore.uiStore.printStatusMessage(
                    i18next.t("screens.kredi_flow.form.error.save_form_error"),
                    MessageType.ERROR
                );
            }
        } else {
            if (closeEditModeAfterSave) {
                this.setIsEditing(false);
            }

            hasSuccessfullySavedCurrentInvoice = true;
        }

        this.setLoadingType(LOADING_TYPE.NONE);

        return hasSuccessfullySavedCurrentInvoice ?? false;
    };

    // Setters
    setInvoices = (invoices: Invoice[]) => {
        this.invoices = invoices;
    };

    setCurrentInvoice = (invoice: Invoice) => {
        this.currentInvoice = invoice;
    };

    setInitialLoadAllInvoicesCompleted = (initialLoadAllInvoicesCompleted: boolean) => {
        this.initialLoadAllInvoicesCompleted = initialLoadAllInvoicesCompleted;
    };

    setIsEditing = (isEditing: boolean) => {
        this.isEditing = isEditing;
    };

    setIsAccountingEditing = (isAccountingEditing: boolean) => {
        this.isAccountingEditing = isAccountingEditing;
    };

    setLoadingType = (loadingType: LOADING_TYPE) => {
        this.loadingType = loadingType;
    };

    setRunningSubscriptionType = (runningSubscriptionType: RUNNING_SUBSCRIPTION_TPYE) => {
        this.runningSubscriptionType = runningSubscriptionType;
    };

    setNumberOfDisplayedItems = (numberOfDisplayedItems: number) => {
        this.numberOfDisplayedItems = numberOfDisplayedItems;
    };

    setSliceSizeOfDisplayedItems = (sliceSizeOfDisplayedItems: number) => {
        this.sliceSizeOfDisplayedItems = sliceSizeOfDisplayedItems;
    };

    setUserDefinedAfterSaveFunction = (userDefinedAfterSaveFunction: () => void) => {
        this.userDefinedAfterSaveFunction = userDefinedAfterSaveFunction;
    };
}

export class Invoice implements IInvoiceProperties {
    rootStore: RootStore;

    id?: string;
    origin?: number;
    workflowInstance?: string;
    invoiceNumber?: string | undefined;
    number?: number;
    amount?: number;
    date?: string;
    dueDate?: string;
    paidDate?: string;
    state?: INVOICE_STATE;
    invoiceType?: INVOICE_TYPE;
    realestate?: IRealestate;
    realestateThumbnailFileId?: string;
    unit?: IUnitData;
    assignedUser?: IAssignedUser | null;
    creditor?: ICreditor;
    paymentaccountid?: string;
    payoutaccountid?: string;
    paymentInfo?: IInvoicePaymentInfo;
    paymentAccount?: IPaymentAccount;
    ticket?: ITicket;
    order?: IOrder;
    creditorInfo?: ICreditorInfo;
    insertUserId?: string;
    invoiceInfo?: IInvoiceInfo;
    workflowUsers?: IWorkflowUser[];
    accountings?: IAccounting[];

    invoiceFile?: IInvoiceFile;

    invoiceFieldsOriginal: IInvoiceProperties = {};

    isValid: boolean = false;

    fieldsWithChanges?: string[] = [];
    fieldsWithChangesPaymentInfo?: string[] = [];
    fieldsWithChangesCreditorInfo?: string[] = [];
    fieldsWithChangesInvoiceInfo?: string[] = [];
    fieldsWithChangesInvoiceAccountings?: string[][] = []; // Is defined as array of arrays because an invoice can have multiple accountings
    fieldsWithChangesInvoiceAccountingsAccount?: string[][] = [];
    fieldsWithChangesInvoiceAccountingsCostCenter?: string[][] = [];

    errors = {
        title: "",
        ibanFormatInvalid: "",
        referenceNumberFormatInvalid: "",
        realestateRequired: ""
    };

    invoiceWorkflowUsersSubscription?: Subscription;
    invoiceStateUpdateSubscription?: Subscription;

    getDefaultPaymentInfoObject = (): IInvoicePaymentInfo => {
        return {
            iban: "",
            type: "",
            reference: ""
        };
    };

    getDefaultCreditorInfoObject = (): ICreditorInfo => {
        return {
            name: "",
            street: "",
            housenumber: "",
            zip: "",
            city: "",
            country: "",
            uid: ""
        };
    };

    getDefaultInvoiceInfoObject = (): IInvoiceInfo => {
        return {
            vatrate: 0,
            vatdate: "",
            conditions: {
                discountRates: [],
                ZahlungsfristTage: 0
            }
        };
    };

    getDefaultAccountingObject = (): IAccounting => {
        return {
            amount: 0,
            date: "",
            text: "",
            account: this.getDefaultAccountObject(),
            costCenter: this.getDefaultCostCenterObject()
        };
    };

    getDefaultAccountObject = (): IAccount => {
        return {
            name: "",
            number: ""
        };
    };

    getDefaultCostCenterObject = (): ICostCenter => {
        return {
            name: "",
            short: ""
        };
    };

    setInvoiceFieldsOnObject = (invoiceFieldObject: IInvoiceProperties, invoiceFields?: IInvoiceProperties) => {
        if (invoiceFields) {
            invoiceFieldObject.id = invoiceFields.id ?? undefined;
            invoiceFieldObject.origin = invoiceFields.origin ?? INVOICE_ORIGIN.MANUAL;
            invoiceFieldObject.workflowInstance = invoiceFields.workflowInstance ?? undefined;
            invoiceFieldObject.number = invoiceFields.number ?? undefined;
            invoiceFieldObject.amount = invoiceFields.amount ?? undefined;
            invoiceFieldObject.date = invoiceFields.date ?? undefined;
            invoiceFieldObject.dueDate = invoiceFields.dueDate ?? undefined;
            invoiceFieldObject.paidDate = invoiceFields.paidDate ?? undefined;
            invoiceFieldObject.state = invoiceFields.state ?? INVOICE_STATE.NEW;
            invoiceFieldObject.invoiceType = invoiceFields.invoiceType ?? INVOICE_TYPE.BILL;
            invoiceFieldObject.realestate = invoiceFields.realestate ?? undefined;
            invoiceFieldObject.realestateThumbnailFileId = invoiceFields.realestateThumbnailFileId ?? undefined;
            invoiceFieldObject.unit = invoiceFields.unit ?? undefined;
            invoiceFieldObject.assignedUser = invoiceFields.assignedUser ?? undefined;
            invoiceFieldObject.creditor = invoiceFields.creditor ?? undefined;
            invoiceFieldObject.invoiceNumber = invoiceFields.invoiceNumber ?? undefined;
            invoiceFieldObject.paymentaccountid = invoiceFields.paymentaccountid ?? undefined;
            invoiceFieldObject.payoutaccountid = invoiceFields.payoutaccountid ?? undefined;
            invoiceFieldObject.paymentInfo = invoiceFields.paymentInfo
                ? { ...invoiceFields.paymentInfo }
                : this.getDefaultPaymentInfoObject();
            invoiceFieldObject.creditorInfo = invoiceFields.creditorInfo
                ? { ...invoiceFields.creditorInfo }
                : this.getDefaultCreditorInfoObject();
            invoiceFieldObject.invoiceInfo = invoiceFields.invoiceInfo
                ? { ...invoiceFields.invoiceInfo }
                : this.getDefaultInvoiceInfoObject();
            invoiceFieldObject.paymentAccount = invoiceFields.paymentAccount ?? undefined;
            invoiceFieldObject.ticket = invoiceFields.ticket ?? undefined;
            invoiceFieldObject.order = invoiceFields.order ?? undefined;
            invoiceFieldObject.invoiceFile = invoiceFields.invoiceFile ?? undefined;
            invoiceFieldObject.workflowUsers = invoiceFields.workflowUsers ?? [];
            invoiceFieldObject.accountings = invoiceFields.accountings
                ? [...invoiceFields.accountings]
                : [this.getDefaultAccountObject()];
        } else {
            invoiceFieldObject.id = undefined;
            invoiceFieldObject.origin = INVOICE_ORIGIN.MANUAL;
            invoiceFieldObject.workflowInstance = undefined;
            invoiceFieldObject.number = undefined;
            invoiceFieldObject.amount = undefined;
            invoiceFieldObject.date = undefined;
            invoiceFieldObject.dueDate = undefined;
            invoiceFieldObject.paidDate = undefined;
            invoiceFieldObject.state = INVOICE_STATE.NEW;
            invoiceFieldObject.invoiceType = INVOICE_TYPE.BILL;
            invoiceFieldObject.realestate = undefined;
            invoiceFieldObject.realestateThumbnailFileId = undefined;
            invoiceFieldObject.unit = undefined;
            invoiceFieldObject.assignedUser = undefined;
            invoiceFieldObject.creditor = undefined;
            invoiceFieldObject.invoiceNumber = undefined;
            invoiceFieldObject.paymentaccountid = undefined;
            invoiceFieldObject.payoutaccountid = undefined;
            invoiceFieldObject.paymentInfo = this.getDefaultPaymentInfoObject();
            invoiceFieldObject.paymentAccount = undefined;
            invoiceFieldObject.ticket = undefined;
            invoiceFieldObject.order = undefined;
            invoiceFieldObject.invoiceFile = undefined;
            invoiceFieldObject.creditorInfo = this.getDefaultCreditorInfoObject();
            invoiceFieldObject.invoiceInfo = this.getDefaultInvoiceInfoObject();
            invoiceFieldObject.workflowUsers = [];
            invoiceFieldObject.accountings = [this.getDefaultAccountingObject()];
        }
    };

    constructor(rootStore: RootStore, ticketFields?: IInvoiceProperties) {
        this.rootStore = rootStore;

        this.setInvoiceFieldsOnObject(this, ticketFields);
        this.setInvoiceFieldsOnObject(this.invoiceFieldsOriginal, ticketFields);

        makeObservable(this, {
            id: observable,
            origin: observable,
            workflowInstance: observable,
            number: observable,
            amount: observable,
            date: observable,
            dueDate: observable,
            paidDate: observable,
            state: observable,
            invoiceNumber: observable,
            invoiceType: observable,
            realestate: observable,
            unit: observable,
            realestateThumbnailFileId: observable,
            invoiceFieldsOriginal: observable,
            fieldsWithChanges: observable,
            fieldsWithChangesPaymentInfo: observable,
            fieldsWithChangesCreditorInfo: observable,
            fieldsWithChangesInvoiceInfo: observable,
            fieldsWithChangesInvoiceAccountings: observable,
            fieldsWithChangesInvoiceAccountingsAccount: observable,
            fieldsWithChangesInvoiceAccountingsCostCenter: observable,
            paymentAccount: observable,
            paymentaccountid: observable,
            paymentInfo: observable,
            creditor: observable,
            assignedUser: observable,
            payoutaccountid: observable,
            isValid: observable,
            errors: observable,
            ticket: observable,
            order: observable,
            invoiceFile: observable,
            creditorInfo: observable,
            invoiceInfo: observable,
            insertUserId: observable,
            workflowUsers: observable,
            invoiceWorkflowUsersSubscription: observable,
            accountings: observable,
            invoiceStateUpdateSubscription: observable,

            setInvoiceFieldsOnObject: action,
            updateProperty: action,
            updatePaymentInfoProperty: action,
            updateCreditorInfoProperty: action,
            updateInvoiceInfoProperty: action,
            updateFieldsWithChanges: action,
            updateFieldsWithChangesAccounting: action,
            updateInvoiceAccountingProperty: action,
            updateInvoiceAccountingAccountProperty: action,
            updateInvoiceAccoutingCostCenterProperty: action,
            resetAllFieldsWithChanges: action,
            reset: action,
            validate: action,
            validateBaseInvoiceDetails: action,
            validatePaymentAccountDetails: action,
            validatePaymentInfoDetails: action,
            validateRealestateInvoiceDetails: action,
            save: action,
            createInvoiceBackend: action,
            updateInvoiceHasura: action,
            startInvoiceWorkflowUsersSubscription: action,
            stopInvoiceWorkflowUsersSubscription: action,
            setInvoiceWorkflowUsersSubscription: action,
            onInvoiceWorkflowUsersDataReceived: action,
            addAccountingRow: action,
            removeAccountingRow: action,
            setAccountingDate: action,
            setAccountingRealestate: action,
            startInvoiceStateUpdateSubscription: action,
            stopInvoiceStateUpdateSubscription: action,
            setInvoiceStateUpdateSubscription: action,
            onInvoiceStateUpdateDataReceived: action,

            hasChanges: computed,
            isNewInvoice: computed,
            isCreditorSelected: computed,
            isRealestateSelected: computed,
            isUnitSelected: computed,
            isTicketSelected: computed,
            isOrderSelected: computed,
            canCurrentUserApproveOrReject: computed,
            hasCurrentUserActionRightsForCurrentInvoiceState: computed,
            accountingDate: computed,
            canCurrentUserSeeStartWorkflowButton: computed
        });
    }

    validateBaseInvoiceDetails = (isValidPreviousCheck: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        return isValid;
    };

    validatePaymentInfoDetails = (isValidPreviousCheck: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.paymentInfo) {
            if (this.paymentInfo.iban) {
                const isIBANValid = validateIBAN(this.paymentInfo.iban);

                if (!isIBANValid) {
                    this.errors.ibanFormatInvalid = i18next.t("screens.kredi_flow.form.invoice.error.invalid_iban");
                    isValid = false;
                } else {
                    this.errors.ibanFormatInvalid = "";
                }
            }

            if (this.paymentInfo.reference) {
                const isReferenceValid = validateInvoiceReferenceNumber(this.paymentInfo.reference);

                if (!isReferenceValid) {
                    this.errors.referenceNumberFormatInvalid = i18next.t(
                        "screens.kredi_flow.form.invoice.error.invalid_reference_number"
                    );
                    isValid = false;
                } else {
                    this.errors.referenceNumberFormatInvalid = "";
                }
            }
        }

        return isValid;
    };

    validatePaymentAccountDetails = (isValidPreviousCheck: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.paymentAccount && this.paymentAccount.iban) {
            const isIBANValid = validateIBAN(this.paymentAccount.iban);

            if (!isIBANValid) {
                this.errors.ibanFormatInvalid = i18next.t("screens.kredi_flow.form.invoice.error.invalid_iban");
                isValid = false;
            } else {
                this.errors.ibanFormatInvalid = "";
            }
        }

        return isValid;
    };

    validateRealestateInvoiceDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (!this.realestate) {
            this.errors.realestateRequired = i18next.t("screens.kredi_flow.form.invoice.error.realestate_required");
            isValid = false;
        } else {
            this.errors.realestateRequired = "";
        }

        return isValid;
    };

    validate = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        isValid = this.validateBaseInvoiceDetails(isValid);
        isValid = this.validatePaymentInfoDetails(isValid);

        this.isValid = isValid;

        return this.isValid;
    };

    reset = () => {
        this.id = undefined;
        this.origin = INVOICE_ORIGIN.MANUAL;
        this.workflowInstance = undefined;
        this.number = undefined;
        this.amount = undefined;
        this.date = undefined;
        this.dueDate = undefined;
        this.paidDate = undefined;
        this.state = INVOICE_STATE.NEW;
        this.invoiceType = INVOICE_TYPE.BILL;
        this.realestate = undefined;
        this.realestateThumbnailFileId = undefined;
        this.assignedUser = undefined;
        this.creditor = undefined;
        this.invoiceNumber = undefined;
        this.paymentaccountid = undefined;
        this.payoutaccountid = undefined;
        this.paymentInfo = {};
        this.paymentAccount = {};
        this.unit = undefined;
        this.ticket = undefined;
        this.order = undefined;
        this.invoiceFile = undefined;
        this.insertUserId = undefined;
        this.creditorInfo = {};
        this.insertUserId = undefined;
        this.invoiceInfo = {};
        this.accountings = [this.getDefaultAccountingObject()];
    };

    updatePaymentInfoProperty = (field: keyof IInvoicePaymentInfo | string, value: any) => {
        // Initialize this.paymentInfo if it's undefined
        if (!this.paymentInfo) {
            this.paymentInfo = {} as IInvoicePaymentInfo;
        }

        if (this.paymentInfo) {
            (this.paymentInfo as any)[field] = value;
        }

        this.updateFieldsWithChanges(INVOICE_FIELD_TYPE.PAYMENT_INFO);
    };

    updateCreditorInfoProperty = (field: keyof ICreditorInfo | string, value: any) => {
        // Initialize this.creditorInfo if it's undefined
        if (!this.creditorInfo) {
            this.creditorInfo = {} as ICreditorInfo;
        }

        if (this.creditorInfo) {
            (this.creditorInfo as any)[field] = value;
        }

        this.updateFieldsWithChanges(INVOICE_FIELD_TYPE.CREDITOR_INFO);
    };

    updateInvoiceInfoProperty = (field: keyof IInvoiceInfo | string, value: any) => {
        // Initialize this.creditorInfo if it's undefined
        if (!this.invoiceInfo) {
            this.invoiceInfo = {} as IInvoiceInfo;
        }

        if (this.invoiceInfo) {
            (this.invoiceInfo as any)[field] = value;
        }

        this.updateFieldsWithChanges(INVOICE_FIELD_TYPE.INVOICE_INFO);
    };

    updateInvoiceAccountingProperty = (index: number, field: keyof IAccounting | string, value: any) => {
        if (this.accountings && this.accountings[index]) {
            (this.accountings[index] as any)[field] = value;
        }

        this.updateFieldsWithChangesAccounting(index, INVOICE_ACCOUNTING_FIELD_TYPE.GENERAL);
    };

    updateInvoiceAccountingAccountProperty = (index: number, field: keyof IAccount | string, value: any) => {
        if (this.accountings && this.accountings[index] && this.accountings[index].account) {
            (this.accountings[index].account as any)[field] = value;
        }

        this.updateFieldsWithChangesAccounting(index, INVOICE_ACCOUNTING_FIELD_TYPE.ACCOUNT);
    };

    updateInvoiceAccoutingCostCenterProperty = (index: number, field: keyof ICostCenter | string, value: any) => {
        if (this.accountings && this.accountings[index] && this.accountings[index].costCenter) {
            (this.accountings[index].costCenter as any)[field] = value;
        }

        this.updateFieldsWithChangesAccounting(index, INVOICE_ACCOUNTING_FIELD_TYPE.COST_CENTER);
    };

    updateProperty = (field: keyof Invoice | string, value: any) => {
        if (field in this) {
            (this as any)[field] = value;
        }

        this.updateFieldsWithChanges(INVOICE_FIELD_TYPE.GENERAL);
    };

    updateFieldsWithChanges = (fieldType: INVOICE_FIELD_TYPE) => {
        if (this.rootStore.invoiceStore.isEditing) {
            switch (fieldType) {
                case INVOICE_FIELD_TYPE.GENERAL:
                    this.fieldsWithChanges = findDifferences(this.invoiceFieldsOriginal, this, true, [
                        INVOICE_PROPERTY.REALESTATE_THUMBNAIL_FILEID,
                        INVOICE_PROPERTY.ASSIGNED_USER,
                        INVOICE_PROPERTY.WORKFLOW_USERS,
                        INVOICE_PROPERTY.STATE
                    ]);
                    break;
                case INVOICE_FIELD_TYPE.PAYMENT_INFO:
                    this.fieldsWithChangesPaymentInfo = findDifferences(
                        this.invoiceFieldsOriginal.paymentInfo,
                        this.paymentInfo,
                        true
                    );
                    break;
                case INVOICE_FIELD_TYPE.CREDITOR_INFO:
                    this.fieldsWithChangesCreditorInfo = findDifferences(
                        this.invoiceFieldsOriginal.creditorInfo,
                        this.creditorInfo,
                        true
                    );
                    break;
                case INVOICE_FIELD_TYPE.INVOICE_INFO:
                    this.fieldsWithChangesInvoiceInfo = findDifferences(
                        this.invoiceFieldsOriginal.invoiceInfo,
                        this.invoiceInfo,
                        true
                    );
                    break;
            }
        }
    };

    updateFieldsWithChangesAccounting = (accountingIndex: number, fieldType: INVOICE_ACCOUNTING_FIELD_TYPE) => {
        if (this.rootStore.invoiceStore.isAccountingEditing) {
            switch (fieldType) {
                case INVOICE_ACCOUNTING_FIELD_TYPE.GENERAL:
                    if (
                        this.accountings &&
                        this.accountings[accountingIndex] &&
                        this.invoiceFieldsOriginal.accountings &&
                        this.invoiceFieldsOriginal.accountings[accountingIndex]
                    ) {
                        if (this.fieldsWithChangesInvoiceAccountings === undefined) {
                            this.fieldsWithChangesInvoiceAccountings = [];
                        }

                        if (this.fieldsWithChangesInvoiceAccountings[accountingIndex] === undefined) {
                            this.fieldsWithChangesInvoiceAccountings[accountingIndex] = [];
                        }

                        this.fieldsWithChangesInvoiceAccountings[accountingIndex] = findDifferences(
                            this.invoiceFieldsOriginal.accountings[accountingIndex],
                            this.accountings[accountingIndex],
                            true
                        );
                    }
                    break;
                case INVOICE_ACCOUNTING_FIELD_TYPE.ACCOUNT:
                    // TODO: Implement
                    break;
                case INVOICE_ACCOUNTING_FIELD_TYPE.COST_CENTER:
                    // TODO: Implement
                    break;
            }
        }
    };

    resetAllFieldsWithChanges = () => {
        this.fieldsWithChanges = [];
        this.fieldsWithChangesPaymentInfo = [];
        this.fieldsWithChangesCreditorInfo = [];
        this.fieldsWithChangesInvoiceInfo = [];
    };

    setIsValid = (isValid: boolean) => {
        this.isValid = isValid;
    };

    save = async (): Promise<boolean> => {
        let upsertInvoiceId: string | undefined = undefined;
        let wasSuccessfullySaved = false;
        let savingNewInvoice = this.isNewInvoice ? true : false;

        const invoiceVariables: IInvoiceUpsertVariables = await this.getInvoiceInsertInputQueryVariables(
            savingNewInvoice
        );

        if (savingNewInvoice) {
            upsertInvoiceId = await this.createInvoiceBackend(invoiceVariables);
        } else {
            upsertInvoiceId = await this.updateInvoiceHasura(invoiceVariables);
        }

        if (
            upsertInvoiceId &&
            !savingNewInvoice &&
            this.invoiceFile &&
            this.invoiceFile.state === INVOICE_FILE_STATE.HAS_CHANGED
        ) {
            await this.saveInvoiceImage(this.invoiceFile, upsertInvoiceId);
        }

        wasSuccessfullySaved = upsertInvoiceId !== undefined;

        if (wasSuccessfullySaved) {
            this.setInvoiceFieldsOnObject(this.invoiceFieldsOriginal, this);
            this.resetAllFieldsWithChanges();
        }

        return wasSuccessfullySaved;
    };

    createInvoiceBackend = async (invoiceVariables: IInvoiceUpsertVariables): Promise<string | undefined> => {
        let upsertIncidentId = undefined;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const createInvoiceResponse = await fetch(NetworkConfig.createInvoiceUrl, {
                method: "POST",
                body: JSON.stringify(invoiceVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (createInvoiceResponse.status === 200) {
                const createIncidentResult = (await createInvoiceResponse.json()) as ICreateInvoiceResponse;

                upsertIncidentId = createIncidentResult.data.invoiceId;

                this.updateProperty(INVOICE_PROPERTY.ID, createIncidentResult.data.invoiceId);
                this.updateProperty(INVOICE_PROPERTY.NUMBER, createIncidentResult.data.Number);
                this.updateProperty(INVOICE_PROPERTY.WORKFLOW_INSTANCE, createIncidentResult.data.workflowInstanceId);
            } else {
                console.error("Error occured while trying to create invoice: ", createInvoiceResponse);
            }
        } catch (error) {
            console.error("Error saving invoice: ", error);
        }

        return upsertIncidentId;
    };

    updateInvoiceHasura = async (invoiceVariables: IInvoiceUpsertVariables): Promise<string> => {
        let upsertInvoiceId = undefined;

        try {
            const { data: upsertInvoiceResult } = await apolloClientInstance.mutate<
                UpsertInvoice,
                UpsertInvoiceVariables
            >({
                mutation: UPSERT_INVOICE,
                variables: {
                    invoice: invoiceVariables
                }
            });

            if (upsertInvoiceResult && upsertInvoiceResult.upserted_invoice_data) {
                upsertInvoiceId = upsertInvoiceResult.upserted_invoice_data.id;
            }
        } catch (error) {
            console.error("Error updating invoice: ", error);
        }

        return upsertInvoiceId;
    };

    getInvoiceInsertInputQueryVariables = async (savingNewInvoice: boolean): Promise<IInvoiceUpsertVariables> => {
        let baseInvoiceVariables: IInvoiceUpsertVariables = {
            origin: savingNewInvoice ? INVOICE_ORIGIN.MANUAL : this.origin,
            type: this.invoiceType,
            date: this.date,
            duedate: this.dueDate,
            realestateid: this.realestate?.id,
            unitid: this.unit?.unitId,
            incidentid: this.ticket?.id,
            orderid: this.order?.id,
            number: this.number,
            amount: this.amount,
            creditorid: this.creditor?.id,
            workflowinstance: this.workflowInstance,
            creditorinfo: isEmpty(this.creditorInfo) ? undefined : this.creditorInfo,
            insertuserid: this.insertUserId
                ? this.insertUserId
                : this.rootStore.authStore.user?.userid
                ? this.rootStore.authStore.user?.userid
                : undefined,
            invoiceinfo: isEmpty(this.invoiceInfo) ? undefined : this.invoiceInfo,
            invoicenumber: this.invoiceNumber,
            paiddate: this.paidDate,
            paymentaccountid: this.paymentaccountid,
            payoutaccountid: this.payoutaccountid,
            paymentinfo: isEmpty(this.paymentInfo) ? undefined : this.paymentInfo
        };

        let invoiceVariables: IInvoiceUpsertVariables = {};

        if (this.isNewInvoice) {
            if (this.invoiceFile && this.invoiceFile.base64String && this.invoiceFile.base64String.length > 0) {
                invoiceVariables = {
                    ...baseInvoiceVariables,
                    file: {
                        invoicepdf: this.invoiceFile.base64String,
                        name: this.invoiceFile.name ?? ""
                    }
                };
            } else {
                invoiceVariables = {
                    ...baseInvoiceVariables
                };
            }
        } else {
            // Updating an existing invoice
            invoiceVariables = {
                ...baseInvoiceVariables,
                id: this.id,
                origin: this.origin
            };
        }

        return invoiceVariables;
    };

    getNextState = (): INVOICE_STATE | undefined => {
        let nextState: INVOICE_STATE | undefined = undefined;

        const navStore = this.rootStore.navStore;

        const numberOfVisas =
            navStore.customer.settings &&
            navStore.customer.settings.cred &&
            navStore.customer.settings.cred.numberOfVisas
                ? navStore.customer.settings.cred.numberOfVisas
                : 0;

        switch (this.state) {
            case INVOICE_STATE.NEW:
                nextState = INVOICE_STATE.READY_FOR_ACCOUNTING;
                break;
            case INVOICE_STATE.READY_FOR_ACCOUNTING:
                nextState = INVOICE_STATE.READY_FOR_VISA;
                break;
            case INVOICE_STATE.READY_FOR_VISA:
                nextState = numberOfVisas > 0 ? INVOICE_STATE.VISA_1_OK : INVOICE_STATE.FULLY_SIGHTED;
                break;
            case INVOICE_STATE.VISA_1_OK:
                nextState = numberOfVisas >= 2 ? INVOICE_STATE.VISA_2_OK : INVOICE_STATE.FULLY_SIGHTED;
                break;
            case INVOICE_STATE.VISA_2_OK:
                nextState = numberOfVisas >= 3 ? INVOICE_STATE.VISA_3_OK : INVOICE_STATE.FULLY_SIGHTED;
                break;
            case INVOICE_STATE.VISA_3_OK:
                nextState = INVOICE_STATE.FULLY_SIGHTED;
                break;
            case INVOICE_STATE.FULLY_SIGHTED:
                nextState = INVOICE_STATE.SENT_TO_ERP;
                break;
            case INVOICE_STATE.SENT_TO_ERP:
                nextState = INVOICE_STATE.PAID;
                break;
            case INVOICE_STATE.PAID:
            case INVOICE_STATE.REJECTED:
            case INVOICE_STATE.CANCELLED:
                nextState = undefined; // No next state
                break;
            default:
                nextState = undefined;
                break;
        }

        return nextState;
    };

    saveInvoiceImage = async (image: IInvoiceFile, invoiceId: string): Promise<string | undefined> => {
        let newFileId = undefined;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const requestBody = {
                version: "2.0",
                id: image.fileId,
                origin: INVOICE_ORIGIN.MANUAL,
                mimetype: image.mimetype,
                extension: image.extension,
                name: image.name,
                filedate: image.fileDate,
                file: image.base64String,
                links: [
                    {
                        id: image.invoiceFileId,
                        entityid: invoiceId,
                        entity: "cred.invoices",
                        roleid: getRoleId(Role.MANAGER),
                        visible: true,
                        type: image.type
                    }
                ]
            };

            const response = await fetch(NetworkConfig.uploadFileUrl, {
                method: "PUT",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            const responseNewFileId = await response.text();

            if (response.ok) {
                newFileId = responseNewFileId;
            } else {
                console.error("Error uploading image");
            }
        } catch (err) {
            console.error("Fetch error occured while trying to upload image: ", err, image);
        }

        return newFileId;
    };

    deleteImage = async (fileId?: string): Promise<boolean> => {
        let success = false;
        if (fileId) {
            try {
                const { data: deleteFileResult } = await apolloClientInstance.mutate<DeleteFile, DeleteFileVariables>({
                    mutation: DELETE_FILE,
                    variables: {
                        fileid: fileId
                    }
                });

                if (
                    deleteFileResult &&
                    deleteFileResult?.deleted_files &&
                    deleteFileResult?.deleted_files?.affected_rows > 0
                ) {
                    success = true;
                }
            } catch (error) {
                console.error("error deleting file: ", error);
            } finally {
                return success;
            }
        }

        return success;
    };

    startInvoiceWorkflowUsersSubscription() {
        this.rootStore.invoiceStore.setRunningSubscriptionType(RUNNING_SUBSCRIPTION_TPYE.WORKFLOW_USERS);

        if (this.invoiceWorkflowUsersSubscription) {
            this.stopInvoiceWorkflowUsersSubscription();
        }

        if (this.id && this.id !== "") {
            const invoiceWorkflowUsersSubscription = apolloClientInstance
                .subscribe<SubscribeToInvoiceWorkflowUserUpdates, SubscribeToInvoiceWorkflowUserUpdatesVariables>({
                    query: SUBSCRIBE_TO_INVOICE_WORKFLOWUSER_UPDATES,
                    variables: { invoiceid: this.id }
                })
                .subscribe({
                    next: ({ data }) => {
                        if (data?.workflowusers && data.workflowusers.length > 0) {
                            this.onInvoiceWorkflowUsersDataReceived(data.workflowusers);
                        } else {
                            this.stopInvoiceWorkflowUsersSubscription();
                        }
                    },
                    error: (err) => {
                        console.error("SUBSCRIBE_TO_INVOICE_WORKFLOWUSER_UPDATES subscription error:", err);
                        this.stopInvoiceWorkflowUsersSubscription();
                    }
                });

            this.setInvoiceWorkflowUsersSubscription(invoiceWorkflowUsersSubscription);
        }
    }

    stopInvoiceWorkflowUsersSubscription() {
        this.rootStore.invoiceStore.setRunningSubscriptionType(RUNNING_SUBSCRIPTION_TPYE.NONE);

        if (this.invoiceWorkflowUsersSubscription) {
            this.invoiceWorkflowUsersSubscription.unsubscribe();
            this.setInvoiceWorkflowUsersSubscription(undefined);
        }
    }

    onInvoiceWorkflowUsersDataReceived = (workflowusers: SubscribeToInvoiceWorkflowUserUpdates_workflowusers[]) => {
        const newWorkflowUsers: IWorkflowUser[] = [];

        workflowusers.forEach((workflowUser) => {
            const workflowUserAlreadyExists =
                this.workflowUsers?.find((user) => user.role === workflowUser.role) !== undefined;

            if (!workflowUserAlreadyExists) {
                const newWorkflowUser: IWorkflowUser = {
                    id: workflowUser.id,
                    role: workflowUser.role as WORKFLOWUSER_ROLE,
                    signaturestamp: workflowUser.signaturestamp,
                    user: {
                        id: workflowUser.user.id,
                        name1: workflowUser.user.name1,
                        name2: workflowUser.user.name2 ?? ""
                    }
                };

                newWorkflowUsers.push(newWorkflowUser);
            }
        });

        const newWorkflowUserArray = [...(this.workflowUsers ?? []), ...newWorkflowUsers];
        this.updateProperty(INVOICE_PROPERTY.WORKFLOW_USERS, newWorkflowUserArray);

        this.resetAllFieldsWithChanges();
        this.stopInvoiceWorkflowUsersSubscription();
    };

    setInvoiceWorkflowUsersSubscription = (invoiceWorkflowUsersSubscription: Subscription | undefined) => {
        this.invoiceWorkflowUsersSubscription = invoiceWorkflowUsersSubscription;
    };

    /* ACCOUNTING (KONTIERUNG) */
    addAccountingRow = () => {
        if (this.accountings) {
            this.accountings.push(this.getDefaultAccountingObject());
        }
    };

    removeAccountingRow = (index: number) => {
        if (this.accountings) {
            this.accountings.splice(index, 1);
        }
    };

    // An invoice can have multiple accountings but all of them will have the same date
    setAccountingDate = (date: string) => {
        if (this.accountings) {
            this.accountings.forEach((accounting) => {
                accounting.date = date;
            });
        }
    };

    setAccountingRealestate = (realestate: IRealestate, accountingIndex?: number) => {
        if (this.rootStore.authStore.user?.erpType === ErpType.IT2) {
            if (this.accountings) {
                this.accountings.forEach((accounting) => {
                    accounting.realestate = realestate;
                });
            }
        } else if (this.rootStore.authStore.user?.erpType === ErpType.RIMO) {
            if (this.accountings && accountingIndex !== undefined) {
                if (this.accountings[accountingIndex]) {
                    this.accountings[accountingIndex].realestate = realestate;
                } else {
                    this.accountings[accountingIndex] = this.getDefaultAccountingObject();
                    this.accountings[accountingIndex].realestate = realestate;
                }
            }
        }
    };

    startInvoiceStateUpdateSubscription() {
        if (this.invoiceStateUpdateSubscription) {
            this.stopInvoiceWorkflowUsersSubscription();
        }

        if (this.id && this.id !== "") {
            const invoiceStateUpdateSubscription = apolloClientInstance
                .subscribe<SubscribeToInvoiceStateUpdate, SubscribeToInvoiceStateUpdateVariables>({
                    query: SUBSCRIBE_TO_INVOICE_STATE_UPDATE,
                    variables: { invoiceid: this.id }
                })
                .subscribe({
                    next: ({ data }) => {
                        if (data?.invoice && data.invoice.length > 0) {
                            this.onInvoiceStateUpdateDataReceived(data.invoice[0]);
                        }
                    },
                    error: (err) => {
                        console.error("SUBSCRIBE_TO_INVOICE_STATE_UPDATE subscription error:", err);
                        this.stopInvoiceStateUpdateSubscription();
                    }
                });

            this.setInvoiceStateUpdateSubscription(invoiceStateUpdateSubscription);
        }
    }

    stopInvoiceStateUpdateSubscription() {
        if (this.invoiceStateUpdateSubscription) {
            this.invoiceStateUpdateSubscription.unsubscribe();
            this.setInvoiceWorkflowUsersSubscription(undefined);
        }
    }

    onInvoiceStateUpdateDataReceived = (invoice: SubscribeToInvoiceStateUpdate_invoice) => {
        const currentState = this.state;
        const newState = invoice.state as INVOICE_STATE;

        if (currentState !== newState) {
            this.updateProperty(INVOICE_PROPERTY.STATE, newState);
        }
    };

    setInvoiceStateUpdateSubscription = (invoiceStateUpdateSubscription: Subscription | undefined) => {
        this.invoiceStateUpdateSubscription = invoiceStateUpdateSubscription;
    };

    get isCreditorSelected(): boolean {
        return this.creditor !== undefined;
    }

    get isRealestateSelected(): boolean {
        return this.realestate !== undefined;
    }

    get isAccountingRealestateSelected(): boolean {
        let isAccountingRealestateSelected = false;
        if (this.rootStore.authStore.user?.erpType === ErpType.IT2) {
            isAccountingRealestateSelected =
                this.accountings !== undefined &&
                this.accountings.length > 0 &&
                this.accountings[0].realestate !== undefined;
        } else if (this.rootStore.authStore.user?.erpType === ErpType.RIMO) {
            isAccountingRealestateSelected = false;
        } else {
            isAccountingRealestateSelected = false;
        }

        return isAccountingRealestateSelected;
    }

    get isUnitSelected(): boolean {
        return this.unit !== undefined;
    }

    get isTicketSelected(): boolean {
        return this.ticket !== undefined;
    }

    get isOrderSelected(): boolean {
        return this.order !== undefined;
    }

    get isNewInvoice(): boolean {
        return this.id === undefined;
    }

    get isFileSelected(): boolean {
        return this.invoiceFile !== undefined;
    }

    get hasChanges(): boolean {
        return (this.fieldsWithChanges && this.fieldsWithChanges?.length > 0) ||
            (this.fieldsWithChangesPaymentInfo && this.fieldsWithChangesPaymentInfo?.length > 0) ||
            (this.fieldsWithChangesCreditorInfo && this.fieldsWithChangesCreditorInfo?.length > 0) ||
            (this.fieldsWithChangesInvoiceInfo && this.fieldsWithChangesInvoiceInfo?.length > 0)
            ? true
            : false;
    }

    get hasCurrentUserActionRightsForCurrentInvoiceState(): boolean {
        let canUserApproveOrReject = false;

        const currentUserId = this.rootStore.authStore.user?.userid;

        let currentUserWorkflowUser: IWorkflowUser | undefined = undefined;

        if (currentUserId) {
            this.workflowUsers?.forEach((workflowUser) => {
                if (workflowUser.user.id === currentUserId) {
                    currentUserWorkflowUser = workflowUser;
                }
            });
        }

        const currentUserIsWorkflowUser = currentUserWorkflowUser !== undefined;

        if (currentUserIsWorkflowUser && currentUserWorkflowUser) {
            activeRoleStates.forEach((activeRoleState) => {
                if (activeRoleState.role === currentUserWorkflowUser?.role && activeRoleState.state === this.state) {
                    canUserApproveOrReject = true;
                }
            });
        }

        return canUserApproveOrReject;
    }

    get canCurrentUserSeeStartWorkflowButton(): boolean {
        return this.state !== undefined && this.state < INVOICE_STATE.READY_FOR_ACCOUNTING;
    }

    get canCurrentUserApproveOrReject(): boolean {
        let canUserApproveOrReject = false;

        if (
            this.state === INVOICE_STATE.READY_FOR_VISA ||
            this.state === INVOICE_STATE.VISA_1_OK ||
            this.state === INVOICE_STATE.VISA_2_OK ||
            this.state === INVOICE_STATE.VISA_3_OK
        ) {
            canUserApproveOrReject = true;
        }

        return canUserApproveOrReject;
    }

    // An invoice can have multiple accountings but all of them will have the same date
    get accountingDate(): string {
        let accountingDate = "";

        if (this.accountings && this.accountings.length > 0 && this.accountings[0].date) {
            accountingDate = this.accountings[0].date;
        }

        return accountingDate;
    }
}
