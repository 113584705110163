import { observable, action, makeObservable, computed, runInAction } from "mobx";
import { GET_SERVICE_PERSON_DATA, SUBCRIPTION_GET_ORDER_STATE, GET_BILLING_DATA } from "src/api/ticket";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GetServicePersonData, GetServicePersonData_service_persons } from "src/api/generated/GetServicePersonData";
import i18next from "i18next";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";
import { INCIDENT_PERSON_ROLE, TICKET_PROPERTY, TICKET_STATE } from "src/stores/TicketStore";
import { ORDER_STATE } from "src/stores/OrderStore";
import { GetBillingData } from "src/api/generated/GetBillingData";

export enum ASSIGN_ORDER_PROCESS {
    SELECT_SERVICE_PROVIDER_ENTITY,
    SERVICE_PROVIDER,
    SERVICE_7000,
    YAROWA
}

export enum ASSIGN_ORDER_PROCESS_STEP {
    SELECT_SERVICE_PROVIDER_ENTITY,
    SELECT_SERVICE_PROVIDER,
    ENTER_SERVICE_PROVIDER_DETAILS,
    ENTER_SERVICE_7000_DETAILS,
    ENTER_YAROWA_DETAILS,
    SUBMITTING_TO_YAROWA,
    SUBMITTING_TO_SERVICE_7000,
    ORDER_CREATION
}

export enum ORDER_CREATION_STATUS {
    INIT,
    IN_PROGRESS,
    SUBMITTING,
    SUCCESS,
    FAILURE
}

export enum CONTRACTOR_TYPE {
    SERVICE_PROVIDER = 1,
    YAROWA = 2,
    SERVICE_7000 = 3
}

interface IProcessStep {
    stepNumber: number;
    stepType: ASSIGN_ORDER_PROCESS_STEP;
    next?: () => IProcessStep | null;
    prev?: () => IProcessStep | null;
    parent: IProcess | null;
}

interface IProcess {
    type: ASSIGN_ORDER_PROCESS;
    processSteps: IProcessStep[];
}

interface IAssignOrderProcess {
    stepNumber: number;
    stepType: ASSIGN_ORDER_PROCESS_STEP;
    processes: IProcess[];
}

export interface IServiceProvider {
    realestateId: string;
    personId: string;
    name1: string;
    name2?: string | null;
    email?: string | null;
    phone?: string | null;
    mobile?: string | null;
}

export interface IBillingData {
    email?: string;
    co?: string;
    name?: string;
    zip?: string;
    city?: string;
    street?: string;
    housenumber?: string;
}

export enum BILLING_DATA_PROPERTY {
    EMAIL = "email",
    CO = "co",
    NAME = "name",
    ZIP = "zip",
    CITY = "city",
    STREET = "street",
    HOUSENUMBER = "housenumber"
}

const BILLING_ADDRESS_OWNER_PLACEHOLDER = "{eigentuemer}";

export enum LOADING_TYPE {
    NONE,
    SERVICE_PERSON_DATA
}

export class AssignOrderModalStore {
    rootStore: RootStore;

    isModalDisplayed = false;

    loadingType: LOADING_TYPE = LOADING_TYPE.NONE;

    areServiceProviderFilteredByRealestate = false;
    serviceProviderData: IServiceProvider[] = [];
    selectedServiceProvider?: IServiceProvider;
    currentServiceProviderSearchQuery: string = "";
    numberOfDisplayedServiceProviders: number = 100;
    sliceSizeOfDisplayedServiceProviders: number = 50;

    currentStepTitle: string = "";

    serviceProviderOrderDescription: string = "";
    orderId: string = "";
    workflowinstance: string = "";
    orderState: number = 0;
    orderStateSubscription: any = null;

    billingDataMaster: IBillingData = {};
    billingData: IBillingData = {};
    isEditingBillingData: boolean = false;

    selectedImageIds: string[] = [];

    MAX_NUMBER_OF_STEPS = 3;

    currentProcessType?: ASSIGN_ORDER_PROCESS = ASSIGN_ORDER_PROCESS.SELECT_SERVICE_PROVIDER_ENTITY;
    currentStepType?: ASSIGN_ORDER_PROCESS_STEP = ASSIGN_ORDER_PROCESS_STEP.SELECT_SERVICE_PROVIDER_ENTITY;
    currentOrderCreationStatus?: ORDER_CREATION_STATUS = ORDER_CREATION_STATUS.INIT;

    assignOrderProcess: IAssignOrderProcess = {
        stepNumber: 1,
        stepType: ASSIGN_ORDER_PROCESS_STEP.SELECT_SERVICE_PROVIDER_ENTITY,
        processes: [
            {
                type: ASSIGN_ORDER_PROCESS.SERVICE_PROVIDER,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: ASSIGN_ORDER_PROCESS_STEP.SELECT_SERVICE_PROVIDER,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        parent: null
                    },
                    {
                        stepNumber: 3,
                        stepType: ASSIGN_ORDER_PROCESS_STEP.ENTER_SERVICE_PROVIDER_DETAILS,
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            },
            {
                type: ASSIGN_ORDER_PROCESS.YAROWA,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: ASSIGN_ORDER_PROCESS_STEP.ENTER_YAROWA_DETAILS,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        parent: null
                    },
                    {
                        stepNumber: 3,
                        stepType: ASSIGN_ORDER_PROCESS_STEP.SUBMITTING_TO_YAROWA,
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            },
            {
                type: ASSIGN_ORDER_PROCESS.SERVICE_7000,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: ASSIGN_ORDER_PROCESS_STEP.ENTER_SERVICE_7000_DETAILS,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        parent: null
                    },
                    {
                        stepNumber: 3,
                        stepType: ASSIGN_ORDER_PROCESS_STEP.SUBMITTING_TO_SERVICE_7000,
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            }
        ]
    };

    errors = {
        serviceOrderDescription: ""
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // Assign parents post object creation
        for (let process of this.assignOrderProcess.processes) {
            for (let step of process.processSteps) {
                step.parent = process;
            }
        }

        makeObservable(this, {
            isModalDisplayed: observable,
            currentProcessType: observable,
            currentStepTitle: observable,
            currentStepType: observable,
            areServiceProviderFilteredByRealestate: observable,
            serviceProviderData: observable,
            selectedServiceProvider: observable,
            currentServiceProviderSearchQuery: observable,
            serviceProviderOrderDescription: observable,
            errors: observable,
            currentOrderCreationStatus: observable,
            orderId: observable,
            workflowinstance: observable,
            orderState: observable,
            orderStateSubscription: observable,
            billingData: observable,
            billingDataMaster: observable,
            isEditingBillingData: observable,
            numberOfDisplayedServiceProviders: observable,
            sliceSizeOfDisplayedServiceProviders: observable,
            loadingType: observable,
            selectedImageIds: observable,

            init: action,
            moveToNextProcessStep: action,
            moveToPrevProcessStep: action,
            setCurrentProcessType: action,
            resetAssignOrderModal: action,
            resetCurrentProcessType: action,
            setIsModalDisplayed: action,
            setAreServiceProviderFilteredByRealestate: action,
            loadServicePersonData: action,
            setCurrentServiceProviderSearchQuery: action,
            toggleServiceProviderSearchFilterRealestate: action,
            setSelectedServiceProvider: action,
            setServicePersonData: action,
            setServiceProviderOrderDescription: action,
            validateServiceOrderDescription: action,
            createOrder: action,
            setCurrentOrderCreationStatus: action,
            setCurrentStepType: action,
            setCurrentStepTitle: action,
            setOrderId: action,
            setOrderState: action,
            orderStateSubscriptionUpdateReceived: action.bound,
            startOrderStateSubscription: action,
            stopOrderStateSubscription: action,
            loadBillingData: action,
            setBillingData: action,
            setBillingDataMaster: action,
            updateBillingDataProperty: action,
            functionsToRunWhenModalIsDisplayed: action,
            functionsToRunWhenModalIsClosed: action,
            setIsEditingBillingData: action,
            setNumberOfDisplayedServiceProviders: action,
            setSliceSizeOfDisplayedServiceProviders: action,
            setLoadingType: action,
            setSelectedImageIds: action,

            processes: computed,
            currentProcess: computed,
            currentStep: computed,
            serviceProviders: computed
        });
    }

    init = async () => {
        await this.loadServicePersonData();
        await this.loadBillingData();

        if (this.rootStore.ticketStore.currentTicket?.realestate?.id) {
            runInAction(() => {
                this.areServiceProviderFilteredByRealestate = true;
            });
        }
    };

    loadServicePersonData = async () => {
        this.setLoadingType(LOADING_TYPE.SERVICE_PERSON_DATA);

        const { data }: { data: GetServicePersonData } = await apolloClientInstance.query<GetServicePersonData>({
            query: GET_SERVICE_PERSON_DATA
        });

        const serviceProviderPersons = data.service_persons
            .map((servicePerson: GetServicePersonData_service_persons) => {
                return {
                    realestateId: servicePerson.realestateid,
                    personId: servicePerson.personid,
                    name1: servicePerson.name1 ?? "",
                    name2: servicePerson.name2,
                    email: servicePerson.email,
                    phone: servicePerson.phonebusiness,
                    mobile: servicePerson.mobile
                };
            })
            .sort((serviceProviderA, serviceProviderB) => {
                if (serviceProviderA.name1 && serviceProviderB.name1) {
                    return serviceProviderA.name1.localeCompare(serviceProviderB.name1);
                } else {
                    return 0;
                }
            });

        this.setServicePersonData(serviceProviderPersons);

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    loadBillingData = async () => {
        const { data }: { data: GetBillingData } = await apolloClientInstance.query<GetBillingData>({
            query: GET_BILLING_DATA
        });

        const billingData: IBillingData = {};

        if (data.billingaddress.length > 0 && data.billingaddress[0].value) {
            const billingAddress = JSON.parse(data.billingaddress[0].value);

            let name = billingAddress.hasOwnProperty("name") ? billingAddress["name"] : undefined;

            if (name === BILLING_ADDRESS_OWNER_PLACEHOLDER) {
                const owner = this.rootStore.ticketStore.currentTicket?.getIncidentPersonByRole(
                    INCIDENT_PERSON_ROLE.OWNER
                );

                if (owner) {
                    name = `${owner.name1} ${owner.name2 ?? ""}`.trim();
                }
            }

            billingData.name = name;
            billingData.co = billingAddress.hasOwnProperty("c/o") ? billingAddress["c/o"] : undefined;
            billingData.city = billingAddress.hasOwnProperty("city") ? billingAddress["city"] : undefined;
            billingData.zip = billingAddress.hasOwnProperty("zip") ? billingAddress["zip"] : undefined;
            billingData.street = billingAddress.hasOwnProperty("street") ? billingAddress["street"] : undefined;
            billingData.housenumber = billingAddress.hasOwnProperty("housenumber")
                ? billingAddress["housenumber"]
                : undefined;
        }

        if (data.billingemail.length > 0 && data.billingemail[0].value) {
            billingData.email = data.billingemail[0].value;
        }

        this.setBillingData(billingData);
        this.setBillingDataMaster(billingData);
    };

    updateBillingDataProperty = (field: keyof IBillingData | string, value: any) => {
        if (this.billingData) {
            (this.billingData as any)[field] = value;
        }
    };

    moveToNextProcessStep() {
        if (this.currentStep && this.currentStep.next && typeof this.currentStep.next === "function") {
            this.currentStepType = this.currentStep.next()?.stepType!;
        }
    }

    moveToPrevProcessStep() {
        if (this.currentStep && this.currentStep.prev && typeof this.currentStep.prev === "function") {
            this.currentStepType = this.currentStep.prev()?.stepType!;
        } else {
            this.resetCurrentProcessType();
        }
    }

    getProcess = (processType: ASSIGN_ORDER_PROCESS) => {
        return this.assignOrderProcess.processes.find((process) => process.type === processType)!;
    };

    resetAssignOrderModal = () => {
        this.resetCurrentProcessType();
        this.currentOrderCreationStatus = ORDER_CREATION_STATUS.INIT;
        this.selectedServiceProvider = undefined;
        this.currentServiceProviderSearchQuery = "";
        this.serviceProviderOrderDescription = "";
    };

    resetCurrentProcessType() {
        this.currentProcessType = ASSIGN_ORDER_PROCESS.SELECT_SERVICE_PROVIDER_ENTITY;
        this.currentStepType = ASSIGN_ORDER_PROCESS_STEP.SELECT_SERVICE_PROVIDER_ENTITY;
    }

    toggleServiceProviderSearchFilterRealestate = () => {
        this.areServiceProviderFilteredByRealestate = !this.areServiceProviderFilteredByRealestate;
    };

    validateServiceOrderDescription = () => {
        let isValid = true;

        if (this.serviceProviderOrderDescription.length === 0) {
            this.errors.serviceOrderDescription = i18next
                .t("screens.tickets.modals.assign_order.errors.empty_service_order_description")
                .toString();

            isValid = false;
        } else {
            this.errors.serviceOrderDescription = "";
        }

        return isValid;
    };

    createOrder = async (): Promise<boolean> => {
        this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.IN_PROGRESS);

        let wasOrderCreationSuccessful = false;

        const createOrderVariables = {
            incidentid: this.rootStore.ticketStore.currentTicket?.id,
            date: this.rootStore.ticketStore.currentTicket?.getDateStringForHasuraQuery(new Date()),
            contractortype:
                this.currentProcessType === ASSIGN_ORDER_PROCESS.SERVICE_PROVIDER
                    ? CONTRACTOR_TYPE.SERVICE_PROVIDER
                    : this.currentProcessType === ASSIGN_ORDER_PROCESS.YAROWA
                    ? CONTRACTOR_TYPE.YAROWA
                    : CONTRACTOR_TYPE.SERVICE_7000,
            contractorid: this.selectedServiceProvider?.personId,
            ordermlslist: [
                {
                    language: i18next.language,
                    instruction: this.serviceProviderOrderDescription
                }
            ],
            billingemail: this.billingData.email ?? undefined,
            billingaddress: {
                name: this.billingData.name ?? undefined,
                co: this.billingData.co ?? undefined,
                street: this.billingData.street ?? undefined,
                housenumber: this.billingData.housenumber ?? undefined,
                zip: this.billingData.zip ?? undefined,
                city: this.billingData.city ?? undefined
            },
            imageIds: this.selectedImageIds
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const createOrderResponse = await fetch(NetworkConfig.createOrderUrl, {
                method: "POST",
                body: JSON.stringify(createOrderVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (createOrderResponse.status === 200) {
                if (
                    this.currentProcessType === ASSIGN_ORDER_PROCESS.SERVICE_7000 ||
                    this.currentProcessType === ASSIGN_ORDER_PROCESS.YAROWA
                ) {
                    const result = await createOrderResponse.json();
                    this.setOrderId(result?.data?.id);
                    this.setWorkflowInstance(result?.data?.workflowInstance);

                    this.startOrderStateSubscription(result?.data?.id);
                    setTimeout(() => {
                        this.stopOrderStateSubscription();
                    }, 30000);
                    this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.SUBMITTING);
                } else {
                    this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.SUCCESS);
                }
                if (this.rootStore.ticketStore.currentTicket) {
                    if (
                        this.rootStore.ticketStore.currentTicket?.state === undefined ||
                        this.rootStore.ticketStore.currentTicket?.state === TICKET_STATE.NEW ||
                        this.rootStore.ticketStore.currentTicket?.state === TICKET_STATE.ASSIGNED
                    ) {
                        this.rootStore.ticketStore.currentTicket?.updateProperty(
                            TICKET_PROPERTY.STATE,
                            TICKET_STATE.COMISSIONED
                        );
                    }
                }

                await this.rootStore.ticketStore.reloadCurrentTicketOrders();
                await this.rootStore.ticketStore.currentTicket?.reloadHistory();

                wasOrderCreationSuccessful = true;
            } else {
                console.error("Error occured while trying to create order: ", createOrderResponse);
            }
        } catch (error) {
            const order = this.orderId.length === 0 ? "" : `'${this.orderId}'`;
            console.error(`Error creating ${order}: `, error);
        }

        if (!wasOrderCreationSuccessful) {
            this.rootStore.uiStore.printStatusMessage(
                i18next.t("screens.tickets.modals.assign_order.order_creation_failure"),
                MessageType.ERROR
            );

            this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.INIT);
        }

        return wasOrderCreationSuccessful;
    };

    orderStateSubscriptionUpdateReceived = (newData: any) => {
        if (
            newData !== undefined &&
            newData.ticket_orders?.length > 0 &&
            newData.ticket_orders?.[0]?.state !== undefined
        ) {
            if (newData.ticket_orders[0].state === ORDER_STATE.SUBMITTED) {
                this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.SUCCESS);
            }
            this.setOrderState(newData.ticket_orders[0].state);

            if (newData.ticket_orders[0].state !== ORDER_STATE.NEW) {
                this.stopOrderStateSubscription();
            }
        }
    };

    startOrderStateSubscription(orderId: string) {
        if (this.orderStateSubscription) {
            // If there is an active subscription, stop it.
            this.stopOrderStateSubscription();
        }

        if (orderId !== undefined && orderId !== "") {
            this.setOrderStateSubscription(
                apolloClientInstance
                    .subscribe({
                        query: SUBCRIPTION_GET_ORDER_STATE,
                        variables: { id: orderId }
                    })
                    .subscribe({
                        next: ({ data }) => {
                            this.orderStateSubscriptionUpdateReceived(data);
                        },
                        error: (err) => {
                            console.error("Order state subscription error:", err);
                            this.stopOrderStateSubscription();
                        }
                    })
            );
        }
    }

    stopOrderStateSubscription() {
        if (this.orderStateSubscription) {
            this.orderStateSubscription.unsubscribe();
            this.setOrderStateSubscription(null);

            if (this.currentOrderCreationStatus !== ORDER_CREATION_STATUS.SUCCESS) {
                this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.FAILURE);
            }
        }
    }

    retryOrderSubmitFromModal = async (): Promise<boolean> => {
        let wasOrderSubmitSuccessful = false;
        if (
            this.currentProcessType !== ASSIGN_ORDER_PROCESS.SERVICE_7000 &&
            this.currentProcessType !== ASSIGN_ORDER_PROCESS.YAROWA
        ) {
            return wasOrderSubmitSuccessful;
        }

        if (this.workflowinstance.length === 0) {
            return await this.createOrder();
        }

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.IN_PROGRESS);

            const orderRetrySubmissionVariables = {
                workflowid: this.workflowinstance
            };

            const orderRetrySubmissionResponse = await fetch(NetworkConfig.orderRetrySubmission, {
                method: "POST",
                body: JSON.stringify(orderRetrySubmissionVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (orderRetrySubmissionResponse.status === 200) {
                wasOrderSubmitSuccessful = true;
            } else {
                const body = await orderRetrySubmissionResponse.json();
                if (body?.code.endsWith("already_submitted")) {
                    wasOrderSubmitSuccessful = true;
                }
            }
        } catch (error) {
            const workflowinstance = this.workflowinstance.length === 0 ? "" : `'${this.workflowinstance}'`;
            console.error(`Error retry submission for order workflow ${workflowinstance}: `, error);
        }

        if (wasOrderSubmitSuccessful) {
            this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.SUCCESS);
            this.setOrderState(ORDER_STATE.SUBMITTED);

            await this.rootStore.ticketStore.reloadCurrentTicketOrders();
            await this.rootStore.ticketStore.currentTicket?.reloadHistory();
        } else {
            const workflowinstance = this.workflowinstance.length === 0 ? "" : `'${this.workflowinstance}'`;
            console.error(`Retry submission unsuccessful for order workflow ${workflowinstance}`);

            this.setCurrentOrderCreationStatus(ORDER_CREATION_STATUS.FAILURE);
        }

        return wasOrderSubmitSuccessful;
    };

    /* SETTERS */
    setCurrentProcessType = (processType: ASSIGN_ORDER_PROCESS) => {
        this.currentProcessType = processType;
        this.currentStepType = this.getProcess(processType).processSteps[0].stepType;
    };

    setCurrentProcessTypeAndTitle = (processType: ASSIGN_ORDER_PROCESS) => {
        this.setCurrentProcessType(processType);
        switch (this.currentProcessType) {
            case ASSIGN_ORDER_PROCESS.SERVICE_PROVIDER:
                this.setCurrentStepTitle(i18next.t("screens.tickets.assign"));
                break;
            case ASSIGN_ORDER_PROCESS.YAROWA:
                this.setCurrentStepTitle(i18next.t("screens.tickets.modals.yarowa"));
                break;
            case ASSIGN_ORDER_PROCESS.SERVICE_7000:
                this.setCurrentStepTitle(i18next.t("screens.tickets.modals.service_7000"));
                break;
            default:
                this.setCurrentStepTitle(i18next.t("screens.tickets.assign"));
                break;
        }
    };

    setCurrentStepType = (stepType: ASSIGN_ORDER_PROCESS_STEP) => {
        this.currentStepType = stepType;
    };

    setCurrentStepTitle = (title: string) => {
        this.currentStepTitle = title;
    };

    functionsToRunWhenModalIsDisplayed = () => {
        this.loadBillingData();
    };

    functionsToRunWhenModalIsClosed = () => {
        this.resetCurrentProcessType();
        this.setIsEditingBillingData(false);
    };

    setIsModalDisplayed = (isModalDisplayed: boolean) => {
        if (isModalDisplayed) {
            this.functionsToRunWhenModalIsDisplayed();
        } else {
            this.functionsToRunWhenModalIsClosed();
        }

        this.isModalDisplayed = isModalDisplayed;
    };

    setAreServiceProviderFilteredByRealestate = (areServiceProviderFilteredByRealestate: boolean) => {
        this.areServiceProviderFilteredByRealestate = areServiceProviderFilteredByRealestate;
    };

    setServicePersonData = (servicePersonData: IServiceProvider[]) => {
        this.serviceProviderData = servicePersonData;
    };

    setCurrentServiceProviderSearchQuery = (currentServiceProviderSearchQuery: string) => {
        this.currentServiceProviderSearchQuery = currentServiceProviderSearchQuery;
    };

    setSelectedServiceProvider = (selectedServiceProvider: IServiceProvider) => {
        this.selectedServiceProvider = selectedServiceProvider;
    };

    setServiceProviderOrderDescription = (serviceProviderOrderDescription: string) => {
        this.serviceProviderOrderDescription = serviceProviderOrderDescription;
    };

    setCurrentOrderCreationStatus = (currentOrderCreationStatus: ORDER_CREATION_STATUS) => {
        this.currentOrderCreationStatus = currentOrderCreationStatus;
    };

    setOrderId = (id: string) => {
        this.orderId = id;
    };

    setWorkflowInstance = (workflowinstance: string) => {
        this.workflowinstance = workflowinstance;
    };

    setOrderState = (currentOrderState: number) => {
        this.orderState = currentOrderState;
    };

    setOrderStateSubscription = (subscription: any) => {
        this.orderStateSubscription = subscription;
    };

    setBillingData = (billingData: IBillingData) => {
        this.billingData = billingData;
    };

    setBillingDataMaster = (billingData: IBillingData) => {
        this.billingDataMaster = billingData;
    };

    setIsEditingBillingData = (isEditingBillingData: boolean) => {
        this.isEditingBillingData = isEditingBillingData;
    };

    setNumberOfDisplayedServiceProviders = (numberOfDisplayedServiceProviders: number) => {
        this.numberOfDisplayedServiceProviders = numberOfDisplayedServiceProviders;
    };

    setSliceSizeOfDisplayedServiceProviders = (sliceSizeOfDisplayedServiceProviders: number) => {
        this.sliceSizeOfDisplayedServiceProviders = sliceSizeOfDisplayedServiceProviders;
    };

    setLoadingType = (loadingType: LOADING_TYPE) => {
        this.loadingType = loadingType;
    };

    setSelectedImageIds = (selectedImageIds: string[]) => {
        this.selectedImageIds = selectedImageIds;
    };

    /* COMPUTED */
    get processes() {
        return this.assignOrderProcess.processes;
    }

    get currentProcess(): IProcess | undefined {
        return this.assignOrderProcess.processes.find((process) => process.type === this.currentProcessType);
    }

    get currentStep(): IProcessStep | undefined {
        return this.currentProcess?.processSteps.find((step) => step.stepType === this.currentStepType);
    }

    get serviceProviders(): IServiceProvider[] {
        const searchQuery = this.currentServiceProviderSearchQuery ?? "";
        const realestateId = this.rootStore.ticketStore.currentTicket?.realestate?.id;

        let filteredServiceProviderData: IServiceProvider[] = [...this.serviceProviderData];

        if (this.areServiceProviderFilteredByRealestate) {
            const filteredServiceProviderDataByRealestate = this.serviceProviderData.filter((serviceProvider) => {
                return serviceProvider.realestateId === realestateId;
            });

            filteredServiceProviderData = [...filteredServiceProviderDataByRealestate];
        }

        const filteredServiceProviderDataBySearchQuery = filteredServiceProviderData.filter((serviceProvider) => {
            const serviceProviderName = `${serviceProvider.name1} ${serviceProvider.name2}`.trim();
            return (
                serviceProviderName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                serviceProvider.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                serviceProvider.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                serviceProvider.mobile?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });

        if (searchQuery === "") {
            return filteredServiceProviderData;
        } else {
            return filteredServiceProviderDataBySearchQuery;
        }
    }
}
