import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_NEWS_DETAILS_BY_ID } from "src/api/news";
import { GetNewsDetailsById, GetNewsDetailsByIdVariables } from "src/api/generated/GetNewsDetailsById";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { withRouter, useParams } from "react-router";
import { NewsEditForm } from "./form/NewsEditForm";

interface Params {
    realestateid: string;
    newsid: string;
    newspreviewnewsid: string;
}

const NewsEditBase = () => {
    const { realestateid, newsid, newspreviewnewsid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);

    useEffect(() => {
        if (newspreviewnewsid) {
            navStore.setBackbutton(
                selectRoute(Route.realestateNewsPreview, authStore.user?.role, {
                    realestateid: realestateid,
                    newsid: newsid
                }),
                t("screens.realestate.news_preview.title").toString()
            );
        } else {
            navStore.setBackbutton(
                selectRoute(Route.dashboard, authStore.user?.role, { realestateid: realestateid }),
                t("screens.realestate.dashboard.title").toString()
            );
        }
    }, [authStore.user?.role, navStore, realestateid, t, newsid, newspreviewnewsid]);

    const { data, loading, error, refetch } = useQuery<GetNewsDetailsById, GetNewsDetailsByIdVariables>(
        GET_NEWS_DETAILS_BY_ID,
        {
            variables: {
                newsid: newsid
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const newsDetails = data.newsitems.length > 0 ? data.newsitems[0] : undefined;

    return (
        <NewsEditForm
            newsDetails={newsDetails}
            newsStatus={data.newsstatus}
            newsid={newsid}
            realestateid={realestateid}
            refetchData={refetch}
        />
    );
};

export const NewsEdit = withRouter(Sentry.withProfiler(observer(NewsEditBase)));
