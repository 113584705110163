import { utcToZonedTime, format } from "date-fns-tz";
import i18next from "i18next";
import { isValidIBAN } from "ibantools";
import { de, fr, enGB, it } from "date-fns/locale";
import { WORKFLOWUSER_ROLE } from "./InvoiceEnums";

/**
 * Formats a given date string into a human-readable date format.
 *
 * @param {string} dateString - The date string to be formatted, expected in the format "YYYY-MM-DD".
 * @param {boolean} [short=false] - If true, formats the date in a short format "d.M.yy" ("18.6.23").
 * @param {boolean} [displayTime=false] - If true, formats the date with a timestamp "18. Juni 2023 - 12:34"
 *
 * @returns {string} - The formatted date string.
 *
 * Examples:
 *
 * formatDate("2023-06-18", true)
 * // Returns: "18.6.23" (short format)
 *
 * formatDate("2023-06-18")
 * // Returns: "18. Juni 2023" (long format, assuming 'i18next.language' is set to "de")
 */
export const formatDate = (dateString: string, short = false, displayTime = false) => {
    const date = new Date(dateString);

    if (short) {
        return format(date, "d.M.yy");
    }

    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric"
    };

    const formattedDate = date.toLocaleDateString(i18next.language, options);
    const [, monthName, year] = formattedDate.split(" ");
    const dayNumber = date.getDate();

    if (displayTime) {
        const time = format(date, "HH.mm");
        return `${dayNumber}. ${monthName} ${year} - ${time}`;
    }

    return `${dayNumber}. ${monthName} ${year}`;
};

export const formatDateForImage = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

export const formatDateHistoryEntry = (dateString: string, adjustTimezoneUtcToZH: boolean) => {
    const dateFormat = "d.M.yy HH:mm";

    if (adjustTimezoneUtcToZH) {
        const timeZone = "Europe/Zurich";
        const zonedDate = utcToZonedTime(dateString + "Z", timeZone);
        return format(zonedDate, dateFormat, { timeZone });
    } else {
        return format(new Date(dateString), dateFormat);
    }
};

export const getTimeStampForHistoryEntry = (dateString: string, adjustTimezoneUtcToZH: boolean) => {
    if (adjustTimezoneUtcToZH) {
        const timeZone = "Europe/Zurich";
        const zonedDate = utcToZonedTime(dateString + "Z", timeZone);
        return zonedDate;
    } else {
        return new Date(dateString);
    }
};

// As described in 'Schweizer Implementation Guidelines QR-Rechnung'
// Anhang B: Prüfzifferberechnung nach Modulo 10 rekursiv
// https://www.six-group.com/dam/download/banking-services/standardization/qr-bill/ig-qr-bill-v2.2-de.pdf

export const PRUEFZIFFER_MATRIX: number[][] = [
    [0, 9, 4, 6, 8, 2, 7, 1, 3, 5],
    [9, 4, 6, 8, 2, 7, 1, 3, 5, 0],
    [4, 6, 8, 2, 7, 1, 3, 5, 0, 9],
    [6, 8, 2, 7, 1, 3, 5, 0, 9, 4],
    [8, 2, 7, 1, 3, 5, 0, 9, 4, 6],
    [2, 7, 1, 3, 5, 0, 9, 4, 6, 8],
    [7, 1, 3, 5, 0, 9, 4, 6, 8, 2],
    [1, 3, 5, 0, 9, 4, 6, 8, 2, 7],
    [3, 5, 0, 9, 4, 6, 8, 2, 7, 1],
    [5, 0, 9, 4, 6, 8, 2, 7, 1, 3]
];

export const validateInvoiceReferenceNumber = (referenceNumber: string): boolean => {
    const referenceNumberWithoutSpaces = referenceNumber.replace(/\s/g, "");

    // Check if the input is exactly 27 digits long and is a number
    if (
        referenceNumberWithoutSpaces.length !== 27 ||
        !/^\d{27}$/.test(referenceNumberWithoutSpaces) ||
        isNaN(Number(referenceNumberWithoutSpaces))
    ) {
        return false;
    }

    let carry = 0;

    // Process each digit except the last one
    for (let i = 0; i < 26; i++) {
        const digit = Number(referenceNumberWithoutSpaces[i]);
        carry = PRUEFZIFFER_MATRIX[carry][digit];
    }

    // Subtract the final carry from 10 to get the expected check digit
    const expectedCheckDigit = (10 - carry) % 10;

    // The last digit is the check digit we want to verify
    const checkDigit = Number(referenceNumberWithoutSpaces[26]);

    // Check if the final carry matches the check digit
    return expectedCheckDigit === checkDigit;
};

export const formatReferenceNumber = (referenceNumber: string): string => {
    return referenceNumber.replace(/(\d{2})(\d{5})(\d{5})(\d{5})(\d{5})(\d+)/, "$1 $2 $3 $4 $5 $6");
};

export const validateIBAN = (iban: string) => {
    const ibanWithoutSpaces = iban.replace(/\s/g, "");

    return isValidIBAN(ibanWithoutSpaces);
};

export const getDateStringForHasuraQuery = (date: any): string => {
    let locale: Locale = de;
    if (i18next.language.includes("de")) locale = de;
    if (i18next.language.includes("en")) locale = enGB;
    if (i18next.language.includes("fr")) locale = fr;
    if (i18next.language.includes("it")) locale = it;

    return format(date, "yyyy-MM-dd", { locale });
};

// returns the date in the format "yyyy-MM-dd" for the html element
export const getDateStringForFormElement = (inputDate: string): string => {
    return format(new Date(inputDate), "yyyy-MM-dd");
};

export const getRoleNameByWorkflowUserRole = (role: WORKFLOWUSER_ROLE): string => {
    switch (role) {
        case WORKFLOWUSER_ROLE.VISA_1:
            return `${i18next.t("screens.kredi_flow.visa")} 1`;
        case WORKFLOWUSER_ROLE.VISA_2:
            return `${i18next.t("screens.kredi_flow.visa")} 2`;
        case WORKFLOWUSER_ROLE.VISA_3:
            return `${i18next.t("screens.kredi_flow.visa")} 3`;
        case WORKFLOWUSER_ROLE.ACCOUNTANT:
            return i18next.t("screens.kredi_flow.accountant");
        default:
            return "";
    }
};
