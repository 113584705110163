import { useCallback, useContext, useEffect, useRef } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "./InvoiceAccounting.module.css";
import { IAccounting } from "src/stores/krediflow/InvoiceTypes";
import { INVOICE_ACCOUNTING_PROPERTY } from "src/stores/krediflow/InvoiceEnums";
import { ErpType } from "src/network/User";
import { RealestateField } from "./RealestateField";

interface InvoiceAccountingProperties {
    accounting: IAccounting;
    accountingIndex: number;
}

const InvoiceAccountingBase = (props: InvoiceAccountingProperties) => {
    const { t } = useTranslation();
    const { invoiceStore, accountingRealestateSearchStore, authStore } = useContext(RootStoreContext);

    const { accounting, accountingIndex } = props;

    const accountInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (
            accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput.isActive &&
            accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput.index === accountingIndex
        ) {
            accountInputRef.current?.focus();
            accountingRealestateSearchStore.setTriggerFocusNextAccountingAccountInput(false);
        }
        // This useEffect only listens to changes in invoiceStore.triggerFocusOnUnitInput
        // eslint-disable-next-line
    }, [accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput]);

    const removeAccountingRow = useCallback(
        (index: number) => () => {
            invoiceStore.currentInvoice?.removeAccountingRow(index);
        },
        [invoiceStore]
    );

    const onChangeAccountingRowField = useCallback(
        (invoiceAccountingProperty: INVOICE_ACCOUNTING_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: any = e.target.value;

            if (invoiceAccountingProperty === INVOICE_ACCOUNTING_PROPERTY.AMOUNT) {
                newValue = Number(newValue);

                if (newValue === 0) {
                    newValue = ""; // This makes the field display the placeholder-text "Betrag"
                }
            }
            if (invoiceStore.currentInvoice) {
                invoiceStore.currentInvoice.updateInvoiceAccountingProperty(
                    accountingIndex,
                    invoiceAccountingProperty,
                    newValue
                );
            }
        },
        [invoiceStore.currentInvoice, accountingIndex]
    );

    const accountField = (
        <UI.Input
            ref={accountInputRef}
            type="text"
            autoComplete="off"
            placeholder={t("screens.kredi_flow.accountings.account").toString()}
            value={accounting.account ? accounting.account.name : ""}
            onChange={onChangeAccountingRowField(INVOICE_ACCOUNTING_PROPERTY.ACCOUNT)}
        />
    );

    const costCenterField = (
        <UI.Input
            placeholder={t("screens.kredi_flow.accountings.costcenter").toString()}
            type="text"
            autoComplete="off"
            value={accounting.costCenter ? accounting.costCenter.name : ""}
            onChange={onChangeAccountingRowField(INVOICE_ACCOUNTING_PROPERTY.COST_CENTER)}
        />
    );

    const bookingTextField = (
        <UI.Input
            placeholder={t("screens.kredi_flow.accountings.text").toString()}
            type="text"
            autoComplete="off"
            value={accounting.text ?? ""}
            onChange={onChangeAccountingRowField(INVOICE_ACCOUNTING_PROPERTY.TEXT)}
        />
    );

    const amountField = (
        <UI.Input
            placeholder={t("screens.kredi_flow.accountings.amount").toString()}
            type="number"
            autoComplete="off"
            value={accounting.amount ?? ""}
            className={styles.AccountingAmountInput}
            onChange={onChangeAccountingRowField(INVOICE_ACCOUNTING_PROPERTY.AMOUNT)}
        />
    );

    const removeAccountingRowIcon =
        accountingIndex > 0 ? (
            <div className={styles.RemoveAccountingRowIcon} onClick={removeAccountingRow(accountingIndex)}>
                <UI.Icon icon={UI.SVGIcon.Close} color={"#970000"} size={25} />
            </div>
        ) : (
            <></>
        );

    const erpType = authStore.user?.erpType;

    return (
        <>
            <UI.Row className={`${styles.AccountingRowContainer} ${accountingIndex > 0 ? "mt-2" : ""}`}>
                <UI.Col lg={3}>
                    {erpType === ErpType.IT2 ? (
                        accountField
                    ) : erpType === ErpType.RIMO ? (
                        <RealestateField accountingRowIndex={accountingIndex} />
                    ) : undefined}
                </UI.Col>
                <UI.Col lg={2}>
                    {erpType === ErpType.IT2 ? costCenterField : erpType === ErpType.RIMO ? accountField : undefined}
                </UI.Col>
                <UI.Col lg={4}>{bookingTextField}</UI.Col>
                <UI.Col lg={2}>{amountField}</UI.Col>
                <UI.Col lg={1}>{removeAccountingRowIcon}</UI.Col>
            </UI.Row>
        </>
    );
};

export const InvoiceAccounting = Sentry.withProfiler(observer(InvoiceAccountingBase));
