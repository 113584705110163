import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import styles from "./RealestateField.module.css";
import { ColorStyle } from "src/utils/Colors";
import { IRealestate } from "src/stores/krediflow/InvoiceTypes";
import { IRealestateData } from "src/stores/RealestateSearchStore";
import { ErpType } from "src/network/User";

interface IRealestateFieldProps {
    accountingRowIndex?: number;
}

const RealestateFieldBase = (props: IRealestateFieldProps) => {
    const { t } = useTranslation();
    const { accountingRealestateSearchStore, invoiceStore, authStore } = useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const [displayRealestateList, setDisplayRealesteateList] = useState<boolean>(false);
    const [lastScrollPositionYRealestateItems, setLastSrollYPositionRealestateItems] = useState<number>(0);

    const realestateInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        const currentSelectedRealestateQueryString =
            accountingRealestateSearchStore.getSelectedRealestateQueryString(accountingRowIndex);
        accountingRealestateSearchStore.setCurrentRealestateSearchQuery(
            currentSelectedRealestateQueryString ?? "",
            accountingRowIndex
        );
    }, [accountingRealestateSearchStore, accountingRowIndex]);

    useEffect(() => {
        const handleClickOutsideRealestateInput = (event: any) => {
            if (
                displayRealestateList &&
                realestateInputRef.current &&
                !realestateInputRef.current.contains(event.target) &&
                !(
                    event.target.classList.contains("list-item") &&
                    event.target.classList.contains("accounting-realestate")
                ) &&
                !(
                    event.target.parentElement.classList.contains("list-item") &&
                    event.target.parentElement.classList.contains("accounting-realestate")
                ) &&
                !(event.target.localName === "path" || event.target.localName === "svg") &&
                !(
                    event.target.classList.contains("highlighted-text") &&
                    event.target.classList.contains("accounting-realestate")
                )
            ) {
                setDisplayRealesteateList(false);

                if (
                    invoiceStore.currentInvoice?.isAccountingRealestateSelected &&
                    accountingRealestateSearchStore.currentRealestateSearchQuery !==
                        accountingRealestateSearchStore.selectedRealestateQueryString
                ) {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideRealestateInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideRealestateInput);
        };
    }, [
        displayRealestateList,
        resetSearchQuery,
        accountingRealestateSearchStore.currentRealestateSearchQuery,
        accountingRealestateSearchStore.selectedRealestateQueryString,
        invoiceStore.currentInvoice?.isAccountingRealestateSelected
    ]);

    const updateAccountingWithSelectedRealestate = useCallback(
        async (realestate: IRealestate) => {
            invoiceStore.updateAccountingWithSelectedRealestate(realestate, accountingRowIndex);
        },
        [invoiceStore, accountingRowIndex]
    );

    const onSelectRealestateItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedRealestateItem =
                accountingRealestateSearchStore.filteredRealestateData(accountingRowIndex)[activeListItemIndex];

            const realestate: IRealestate = {
                id: selectedRealestateItem.id,
                number: selectedRealestateItem.number,
                name: selectedRealestateItem.name,
                zip: selectedRealestateItem.zip,
                city: selectedRealestateItem.city
            };

            updateAccountingWithSelectedRealestate(realestate);
        },
        [updateAccountingWithSelectedRealestate, accountingRealestateSearchStore, accountingRowIndex]
    );

    const focusOnNextAccountingAccountField = useCallback(() => {
        const erpType = authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            accountingRealestateSearchStore.setTriggerFocusNextAccountingAccountInput(true);
        }

        if (erpType === ErpType.RIMO) {
            accountingRealestateSearchStore.setTriggerFocusNextAccountingAccountInput(true, accountingRowIndex);
        }
    }, [accountingRealestateSearchStore, authStore.user?.erpType, accountingRowIndex]);

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "accounting-realestate",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayRealesteateList,
        isListDisplayed: displayRealestateList,
        onSelectItem: onSelectRealestateItem,
        resetSearchQuery: resetSearchQuery,
        additionalTabActionWithNoItemSelected: focusOnNextAccountingAccountField
    });

    const resetAmountOfItemsDisplayedInRealestateList = useCallback(() => {
        setLastSrollYPositionRealestateItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onChangeRealestateInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInRealestateList();

            const inputRealestate = e.target.value;
            accountingRealestateSearchStore.setCurrentRealestateSearchQuery(inputRealestate, accountingRowIndex);
        },
        [accountingRealestateSearchStore, resetAmountOfItemsDisplayedInRealestateList, accountingRowIndex]
    );

    const onFocusRealestateInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (invoiceStore.currentInvoice?.isRealestateSelected && realestateInputRef.current) {
                realestateInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInRealestateList();
            setDisplayRealesteateList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInRealestateList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice?.isRealestateSelected
        ]
    );

    const onClickRealestateItem = useCallback(
        (realestateData: IRealestateData) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayRealesteateList(false);

            const realestate: IRealestate = {
                id: realestateData.id,
                number: realestateData.number,
                name: realestateData.name,
                zip: realestateData.zip,
                city: realestateData.city
            };

            updateAccountingWithSelectedRealestate(realestate);
        },
        [setActiveListItemIndex, NO_LIST_ITEM_SELECTED, updateAccountingWithSelectedRealestate]
    );

    const onScrollRealestateList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYRealestateItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems <
                accountingRealestateSearchStore.filteredRealestateData(accountingRowIndex).length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionRealestateItems(pixelsFromTop);
        },
        [lastScrollPositionYRealestateItems, invoiceStore, accountingRealestateSearchStore, accountingRowIndex]
    );

    const realestateSelectionList = (
        <ul
            id="accounting-realestate-dropdown-list"
            className={`dropdown-list accounting-realestate krediflow ${
                authStore.user?.erpType === ErpType.IT2 ? "IT2" : "RIMO"
            }`}
            onScroll={onScrollRealestateList}
        >
            {accountingRealestateSearchStore
                .filteredRealestateData(accountingRowIndex)
                .slice(0, invoiceStore.numberOfDisplayedItems)
                .map((realestate, index) => {
                    const realestateName = `${realestate.nameZipCity} [${realestate.number}]`;

                    const currentRealstateSearchQuery =
                        accountingRealestateSearchStore.getCurrentRealestateSearchQuery(accountingRowIndex);

                    const formattedRealestateName = boldenMatchedSubstring(
                        currentRealstateSearchQuery ?? "",
                        realestateName,
                        "highlighted-text accounting-realestate"
                    );

                    return (
                        <li
                            key={index}
                            className="d-flex align-items-center list-item accounting-realestate"
                            onClick={onClickRealestateItem(realestate)}
                            id={`accounting-realestate-list-item-${index}`}
                        >
                            <UI.Icon
                                icon={realestate.consolidation ? UI.SVGIcon.Realestates : UI.SVGIcon.Realestate}
                                size={25}
                                circular
                                color={ColorStyle("grey")}
                                backgroundColor="#D2D5D9"
                                className="accounting-realestate-icon"
                            />
                            <div
                                className={`accounting-realestate-text ${styles.AccountingRealestateText}`}
                                dangerouslySetInnerHTML={{
                                    __html: formattedRealestateName
                                }}
                            />
                        </li>
                    );
                })}
        </ul>
    );

    const onBlurRealestateInput = useCallback(() => {
        invoiceStore.currentInvoice?.validateRealestateInvoiceDetails();
    }, [invoiceStore]);

    const readOnlyRealestateElement = (
        <div className="view-only-container mt-3 mt-lg-0">
            <div className="accounting-realestate bold-text mb-1">{t("screens.realestate.title")}</div>
            <div>
                <span className="text-with-number">{invoiceStore.currentInvoice?.realestate?.name}</span>
                <span>{`${
                    invoiceStore.currentInvoice?.realestate?.number
                        ? `[${invoiceStore.currentInvoice?.realestate?.number}]`
                        : ""
                }`}</span>
            </div>

            <div>
                {invoiceStore.currentInvoice?.realestate?.zip} {invoiceStore.currentInvoice?.realestate?.city}
            </div>
        </div>
    );

    return (
        <>
            {invoiceStore.isAccountingEditing ? (
                <>
                    <UI.Input
                        ref={realestateInputRef}
                        label={
                            authStore.user?.erpType === ErpType.IT2
                                ? `${t("screens.kredi_flow.accountings.mandant").toString()} *`
                                : undefined
                        }
                        placeholder={
                            authStore.user?.erpType === ErpType.RIMO
                                ? `${t("screens.realestate.title").toString()}`
                                : undefined
                        }
                        type="text"
                        autoComplete="off"
                        value={
                            accountingRealestateSearchStore.getCurrentRealestateSearchQuery(accountingRowIndex) ?? ""
                        }
                        onChange={onChangeRealestateInput}
                        onFocus={onFocusRealestateInput}
                        onKeyDown={onKeyDownFunction}
                        onBlur={onBlurRealestateInput}
                        disabled={!invoiceStore.isAccountingEditing}
                    />
                    {displayRealestateList ? realestateSelectionList : undefined}
                </>
            ) : (
                readOnlyRealestateElement
            )}
        </>
    );
};

export const RealestateField = Sentry.withProfiler(observer(RealestateFieldBase));
