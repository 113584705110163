import { CONTRACTOR_TYPE } from "src/stores/OrderStore";
import {
    INVOICE_ACCOUNT_TYPE,
    INVOICE_ACCOUNT_UNIT_OF_MEASURE,
    INVOICE_ACCOUNT_VAT_CONFIG,
    INVOICE_FILE_STATE,
    INVOICE_FILE_TYPE,
    INVOICE_LIST_STATE,
    INVOICE_STATE,
    INVOICE_TYPE,
    SORT,
    WORKFLOWUSER_ROLE
} from "./InvoiceEnums";
import { cred_invoices_insert_input } from "src/api/generated/globalTypes";

export interface IAssignedUser {
    id: string;
    name1: string;
    name2?: string | null;
}

export interface IRealestate {
    id: string;
    number: string;
    name: string;
    zip: string;
    city: string;
}

export interface IUnitData {
    houseId: string;
    unitId: string;
    unitNumber?: number;
    unitName: string;
    tenancyData?: ITenancyData;
}

export interface ITenancyData {
    tenancyid?: string;
    tenantid?: string;
}

export interface ICreditor {
    id: string;
    name: string; // From the creditor (cred.creditors)
    personId?: string;
    name1?: string | null; // From the person (common.persons) - Is normally identical to the creditor name
    name2?: string | null; // From the person (common.persons) - Is used as the name suffix (Namenszusatz)
    mobile?: string | null;
    phonebusiness?: string | null;
    email?: string | null;
    gender?: number | null;
    street?: string | null;
    housenumber?: string | null;
    zip?: string | null;
    city?: string | null;
    origin?: number | null;
    paymentAccounts?: IPaymentAccount[];
}

export interface IInvoicePaymentInfo {
    iban?: string;
    type?: string;
    reference?: string;
}

export interface ICreditorInfo {
    name?: string;
    street?: string;
    housenumber?: string;
    zip?: string;
    city?: string;
    country?: string;
    uid?: string;
}

export interface IInvoiceInfo {
    vatrate?: number;
    vatdate?: string;
    conditions?: IInvoiceInfoConditions;
}

export interface IInvoiceType {
    name: string;
    type: INVOICE_TYPE;
}

export interface IInvoiceProperties {
    id?: string;
    origin?: number;
    workflowInstance?: string;
    number?: number;
    invoiceNumber?: string;
    amount?: number;
    date?: string;
    dueDate?: string;
    paidDate?: string;
    state?: INVOICE_STATE;
    invoiceType?: INVOICE_TYPE;
    realestate?: IRealestate;
    realestateId?: string;
    realestateThumbnailFileId?: string;
    unit?: IUnitData;
    assignedUser?: IAssignedUser | null;
    creditor?: ICreditor;
    paymentaccountid?: string;
    payoutaccountid?: string;
    paymentInfo?: IInvoicePaymentInfo;
    paymentAccount?: IPaymentAccount;
    ticket?: ITicket;
    order?: IOrder;
    invoiceFile?: IInvoiceFile;
    creditorInfo?: ICreditorInfo;
    insertUserId?: string;
    invoiceInfo?: IInvoiceInfo;
    workflowUsers?: IWorkflowUser[];
    accountings?: IAccounting[];
}

export interface IInvoiceFilter {
    state: INVOICE_LIST_STATE;
    sort: SORT;
    query: string;
    types: INVOICE_TYPE[];
    showOnlyMyInvoices: boolean;
    showOnlyInvoicesFromMyRealestates: boolean;
}

export interface ISearchTag {
    label: string;
    id: string;
    active: boolean;
    type?: INVOICE_TYPE;
}

export interface IPaymentAccount {
    id?: string;
    iban?: string;
    name?: string;
    type?: number;
}

export interface ITicket {
    id?: string;
    realestateid?: string;
    unitid?: string;
    date?: string; // 01.07.2024
    date_long?: string; // 1. Juli 2024
    number?: string;
    title?: string;
}

export interface IOrder {
    id?: string;
    number?: string;
    realestateid?: string;
    date?: string; // 01.07.2024
    date_long?: string; // 1. Juli 2024
    contractorId?: string;
    title?: string;
    instruction?: string;
    contractorName1?: string;
    contractorName2?: string;
    ticket?: ITicket;
    contractorType?: CONTRACTOR_TYPE;
}

export interface IInvoiceFile {
    invoiceFileId?: string;
    fileId?: string;
    name?: string;
    url?: string;
    type?: INVOICE_FILE_TYPE;
    base64String?: string;
    mimetype?: string;
    extension?: string;
    fileDate?: string;
    state?: INVOICE_FILE_STATE;
}

export interface IInvoiceUpsertVariables extends cred_invoices_insert_input {
    file?: IInvoicePDFInsertVariables;
}

export interface IInvoicePDFInsertVariables {
    invoicepdf: string; // base64 string of the pdf
    name: string;
}

export interface ICreateInvoiceResponse {
    data: {
        invoiceId: string;
        workflowInstanceId: string;
        FileId: string;
        Number: number;
    };
}

export interface IWorkflowUserDetails {
    id: string;
    name1: string;
    name2: string;
}

export interface IWorkflowUser {
    id: string;
    role: WORKFLOWUSER_ROLE;
    signaturestamp: string;
    user: IWorkflowUserDetails;
}

export interface IActiveRoleState {
    role: WORKFLOWUSER_ROLE;
    state: INVOICE_STATE;
}

export const activeRoleStates: IActiveRoleState[] = [
    { role: WORKFLOWUSER_ROLE.NONE, state: INVOICE_STATE.NEW },
    { role: WORKFLOWUSER_ROLE.NONE, state: INVOICE_STATE.READY_FOR_ACCOUNTING },
    { role: WORKFLOWUSER_ROLE.VISA_1, state: INVOICE_STATE.READY_FOR_VISA },
    { role: WORKFLOWUSER_ROLE.VISA_2, state: INVOICE_STATE.VISA_1_OK },
    { role: WORKFLOWUSER_ROLE.VISA_3, state: INVOICE_STATE.VISA_2_OK },
    { role: WORKFLOWUSER_ROLE.ACCOUNTANT, state: INVOICE_STATE.VISA_3_OK },
    { role: WORKFLOWUSER_ROLE.ACCOUNTANT, state: INVOICE_STATE.FULLY_SIGHTED },
    { role: WORKFLOWUSER_ROLE.ACCOUNTANT, state: INVOICE_STATE.SENT_TO_ERP },
    { role: WORKFLOWUSER_ROLE.ACCOUNTANT, state: INVOICE_STATE.PAID },
    { role: WORKFLOWUSER_ROLE.ACCOUNTANT, state: INVOICE_STATE.REJECTED },
    { role: WORKFLOWUSER_ROLE.NONE, state: INVOICE_STATE.CANCELLED }
];

export interface IAccounting {
    id?: string;
    amount?: number;
    date?: string;
    realestate?: IRealestate; // Liegenschaft / Mandant
    account?: IAccount;
    costCenter?: ICostCenter;
    text?: string;
}

export interface IAccount {
    id?: string;
    name?: string;
    number?: string;
    type?: INVOICE_ACCOUNT_TYPE;
    validFrom?: string;
    validTo?: string;
    realestate?: IRealestate;
    tenant?: {}; // TODO: Define ITenant Interface once needed
    requiresCostCenter?: boolean;
    requiredQuantity?: boolean;
    unitOfMeasure?: INVOICE_ACCOUNT_UNIT_OF_MEASURE;
    vatConfig?: INVOICE_ACCOUNT_VAT_CONFIG;
    extraCostDate?: boolean;
}

export interface ICostCenter {
    id?: string;
    name?: string;
    short?: string;
}

// Types for QR extrated data
export interface IQRExtractInvoiceData {
    invoicenumber: string | null;
    date: string | null;
    duedate: string | null;
    paiddate: string | null;
    amount: number;
    creditorid: string | null;
    paymentaccountid: string | null;
    payoutaccountid: string | null;
    paymentinfo: IInvoicePaymentInfo;
    realestateid: string | null;
    incidentid: string | null;
    orderid: string | null;
    unitid: string | null;
    creditorinfo: ICreditorInfo;
    invoiceinfo: IInvoiceInfo;
}

export interface IInvoiceInfoConditions {
    discountRates: IInvoiceInfoDiscountRate[] | null;
    ZahlungsfristTage: number | null;
}

export interface IInvoiceInfoDiscountRate {
    precentage: number | null;
    days: number | null;
}
