import React, { useEffect, useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { RouteComponentProps, withRouter } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { UI, ScreenSize } from "@wwimmo/ui";
import { GET_COMMON_UNIT_BY_ID } from "src/api/realestateUnits";
import {
    CommonUnitById,
    CommonUnitByIdVariables,
    CommonUnitById_unit_tenancys_today_rentalsInTenancys,
    CommonUnitById_unit_tenancys_today_rentalsOutTenancys
} from "src/api/generated/CommonUnitById";
import { convert } from "src/utils/Convert";
import { dateFormat } from "src/utils/Date";
import { Route, selectRoute } from "src/config/routes";
import * as Screens from "src/screens";
import { runInAction } from "mobx";
import { ErpType } from "src/network/User";
import { ScreenWithTiles } from "src/components/screenWithTiles/ScreenWithTiles";
import { observer } from "mobx-react-lite";
import { ScreenType } from "src/api/tiles";
import { getLang } from "src/utils/i18n";
import { NetworkConfig } from "src/network/NetworkConfig";
import { Role } from "src/network/User";
import { getTenancyChangeInformation } from "src/utils/TenancyChangeHelper";
import UnitFileList from "./file-list/UnitFileList";
import styles from "./Unit.module.css";
import { getCurrencyElement } from "src/utils/Currency";

interface MatchParams {
    realestateid: string;
    unitid: string;
}

interface UnitItem {
    key?: string | number;
    label?: string;
    value?: any;
    to?: string;
    onClick?: any;
}

interface UnitTenantItem {
    key?: string | number;
    period?: string;
    name?: any;
    accountbalance?: any;
    status?: string;
    to?: string;
    onClick?: any;
    classNameRow?: string;
}

interface SingleValueItem {
    key?: string | number;
    value?: any;
    to?: string;
    onClick?: any;
}

const UnitBase = (props: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const realestateid = props.match.params.realestateid;
    const unitid = props.match.params.unitid;
    const { loading, error, data } = useQuery<CommonUnitById, CommonUnitByIdVariables>(GET_COMMON_UNIT_BY_ID, {
        variables: { unitId: unitid, lang: getLang() }
    });

    useEffect(() => {
        runInAction(() => {
            navStore.resetActionButtons();
            navStore.setBackbutton(
                selectRoute(Route.units, authStore.user?.role, {
                    realestateid: realestateid
                }),
                navStore.isCondominium
                    ? t("screens.realestate.units.co_title").toString()
                    : t("screens.realestate.units.title").toString()
            );
            const unitData = data?.unit[0];
            unitData?.name && navStore.setTitle(unitData?.name);
        });
    }, [t, authStore.user, navStore, realestateid, uiStore, data?.unit]);

    const renderItem = useCallback((item: UnitItem, screenSize: ScreenSize) => {
        const listRows: any[] = [];

        if (item.value || item.to) {
            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={item.label} />
                    <UI.List.Cell key={"c-2"} colspan={screenSize === ScreenSize.DESKTOP ? 2 : 1} value={item.value} />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return (
                <UI.List.Item
                    key={`${item.label}${item.key ?? ""}`}
                    screenSize={screenSize}
                    rows={listRows}
                    to={item.to}
                    onClick={item.onClick}
                />
            );
        }
    }, []);

    const renderTenantItem = useCallback(
        (displayTenantAccountBalanceRow: boolean) => (tenantItem: UnitTenantItem, screenSize: ScreenSize) => {
            const listRows: any[] = [];

            let rows: React.ReactElement[] = [];

            uiStore.setTenantItemName(tenantItem.name);

            if (tenantItem.period || tenantItem.to) {
                switch (screenSize) {
                    case ScreenSize.DESKTOP:
                        rows = [
                            <UI.List.Row key={"row-1"} className={tenantItem.classNameRow ?? ""}>
                                <UI.List.Cell
                                    key={"c-1"}
                                    colspan={displayTenantAccountBalanceRow ? 0.6 : 0.4}
                                    value={tenantItem.period}
                                />
                                <UI.List.Cell
                                    key={"c-2"}
                                    colspan={displayTenantAccountBalanceRow ? 0.8 : 0.4}
                                    value={tenantItem.name}
                                />
                                <UI.List.Cell
                                    key={"c-3"}
                                    colspan={displayTenantAccountBalanceRow ? 0.4 : 0.8}
                                    value={tenantItem.status}
                                />
                                {displayTenantAccountBalanceRow ? (
                                    <UI.List.Cell key={"c-4"} colspan={0.4} value={tenantItem.accountbalance} />
                                ) : undefined}
                            </UI.List.Row>
                        ];
                        break;
                    case ScreenSize.MOBILE:
                        rows = [
                            <UI.List.Row key={"row-1"} className={tenantItem.classNameRow ?? ""}>
                                <UI.List.Cell key={"c-1"} colspan={1} value={tenantItem.period} />
                                <UI.List.Cell key={"c-2"} colspan={0.5} value={tenantItem.name} />
                            </UI.List.Row>
                        ];
                        break;
                }

                listRows.push(rows);

                return (
                    <UI.List.Item
                        key={`${tenantItem.name}${tenantItem.key ?? ""}`}
                        screenSize={screenSize}
                        rows={listRows}
                        to={tenantItem.to}
                        onClick={tenantItem.onClick}
                    />
                );
            }
        },
        [uiStore]
    );

    const renderTenantListHeader = useCallback(
        (displayTenantAccountBalanceRow: boolean) => (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={displayTenantAccountBalanceRow ? 0.6 : 0.4}
                                value={t("screens.unit.period")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={displayTenantAccountBalanceRow ? 0.8 : 0.4}
                                value={t("screens.unit.name")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={displayTenantAccountBalanceRow ? 0.4 : 0.8}
                                value={t("screens.unit.status")}
                            />
                            {displayTenantAccountBalanceRow ? (
                                <UI.List.Cell key={"c-4"} colspan={0.4} value={t("screens.unit.saldo")} />
                            ) : undefined}
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.unit.period")} />
                            <UI.List.Cell key={"c-2"} colspan={0.5} value={t("screens.unit.name")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    const renderSingleValueItem = useCallback((item: SingleValueItem, screenSize: ScreenSize) => {
        const listRows: any[] = [];

        if (item.value || item.to) {
            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={item.value} />
                </UI.List.Row>
            ];

            listRows.push(rows);

            return (
                <UI.List.Item
                    key={`${item.value}${item.key ?? ""}`}
                    screenSize={screenSize}
                    rows={listRows}
                    to={item.to}
                    onClick={item.onClick}
                />
            );
        }
    }, []);

    const onRowClick = useCallback(() => {
        navStore.setBackbutton(
            selectRoute(Route.unit, authStore.user?.role, {
                realestateid: realestateid,
                unitid: unitid
            }),
            t("screens.unit.title").toString()
        );
    }, [navStore, t, authStore.user?.role, unitid, realestateid]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data || data.unit.length === 0 || !data.unit[0].tenancys_today[0]) {
        return <Screens.ErrorEmptyData message={t("error.nodata")} />;
    }

    const getEnumerationMap = (enumerations: Array<any>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const utilizations = getEnumerationMap(data.utilizations);
    const tenancypersonroles = getEnumerationMap(data.tenancypersonroles);
    const rentalstates = getEnumerationMap(data.rentalstates);
    const category = getEnumerationMap(data.category);

    const getTenancyStatus = (
        leasestartDate: any,
        leaseendDate: any,
        rentalsOutTenancyArray: CommonUnitById_unit_tenancys_today_rentalsOutTenancys[],
        rentalsInTenancyArray: CommonUnitById_unit_tenancys_today_rentalsInTenancys[]
    ): string => {
        let returnState = "";
        let today = new Date();

        let leasestart = undefined;
        if (leasestartDate) {
            leasestart = new Date(leasestartDate);
        }

        let leaseend = undefined;
        if (leaseendDate) {
            leaseend = new Date(leaseendDate);
        }

        if (
            leasestart &&
            leasestart <= today &&
            (leaseend === undefined || leaseend >= today) &&
            rentalsOutTenancyArray.length === 0
        ) {
            returnState = l("rentalstate.active");
        } else if (
            leasestart &&
            leasestart <= today &&
            leaseend &&
            leaseend >= today &&
            rentalsOutTenancyArray.length > 0
        ) {
            returnState = l("rentalstate.terminated");
        } else if (leasestart && leasestart > today && rentalsInTenancyArray.length === 0) {
            returnState = "";
        } else if (leasestart && leasestart > today && rentalsInTenancyArray.length > 0) {
            const rentalsInTenacy = rentalsInTenancyArray[0];

            if (rentalstates.has(rentalsInTenacy.state)) {
                returnState = rentalstates.get(rentalsInTenacy.state).label;
            }
        }

        return returnState;
    };

    const unitData = data.unit[0];
    const tenancyData = data.unit[0].tenancys_today[0];
    const unitFiles = unitData.unitfiles;

    const sortedUnitFiles = [...unitFiles].sort((a, b) => {
        const dateA = a.filedate ? new Date(a.filedate).getTime() : 0;
        const dateB = b.filedate ? new Date(b.filedate).getTime() : 0;
        return dateB - dateA;
    });

    const l = (key: string) => {
        return t("screens.unit." + key);
    };

    const getDate = (input: string) => {
        if (input) {
            return dateFormat(new Date(input), "dd.MM.yyyy");
        }
    };

    const erpTypeUser = authStore.user?.erpType ? authStore.user.erpType : 0;

    const getNoticePeriod = (noticePeriod: string | number, erpTypeUser: ErpType | number): string => {
        if (!noticePeriod) return "";

        const noticePeriodNumber = +noticePeriod;

        let periodSingular = "";
        let periodPlural = "";

        let month = t("others.units.month");
        let months = t("others.units.months");
        let day = t("others.units.day");
        let days = t("others.units.days");

        switch (erpTypeUser) {
            case ErpType.IT2:
                periodSingular = month;
                periodPlural = months;
                break;
            case ErpType.RIMO:
                periodSingular = day;
                periodPlural = days;
                break;
            default:
                if (noticePeriodNumber >= 30) {
                    periodSingular = day;
                    periodPlural = days;
                } else {
                    periodSingular = month;
                    periodPlural = months;
                }
                break;
        }

        if (noticePeriodNumber === 1) {
            return `${noticePeriodNumber} ${periodSingular}`;
        } else {
            return `${noticePeriodNumber} ${periodPlural}`;
        }
    };

    let floor = undefined;

    if (unitData.floor !== undefined && unitData.floor !== null) {
        if (unitData.floor === 0) {
            floor = "EG";
        } else if (unitData.floor > 0) {
            floor = `${unitData.floor} OG`;
        } else {
            floor = `${Math.abs(unitData.floor)} UG`;
        }
    }

    let unitAppliancesLink = undefined;
    let unitMaintenancesLink = undefined;

    if (navStore.hasCustomerOptionAppliances) {
        unitAppliancesLink = selectRoute(Route.unitAppliances, authStore.user?.role, {
            realestateid: realestateid,
            unitid: unitid
        });
    }
    if (navStore.hasOptionMaintenances) {
        unitMaintenancesLink = selectRoute(Route.unitMaintenances, authStore.user?.role, {
            realestateid: realestateid,
            unitid: unitid
        });
    }

    let unitDetailsEasyContact: Array<SingleValueItem> = [];

    if (navStore.hasFeatureEasyContact) {
        const easyContactTenantToken = btoa(`U=${unitid}`);

        unitDetailsEasyContact.push({
            value: l("unit_submit_easycontact_report"),
            to: `${NetworkConfig.proxy}/ec/start?t=${easyContactTenantToken}`
        });
    }

    const unitDetails: Array<UnitItem> = [
        { label: l("tenantnumber"), value: unitData.number },
        { label: l("tenantname"), value: unitData.name },
        {
            label: l("space"),
            value:
                unitData.space !== 0.0 ? (
                    <span>
                        {unitData.space} m<sup>2</sup>
                    </span>
                ) : undefined
        },
        { label: l("utilization"), value: utilizations.get(unitData.utilization).label },
        { label: l("category"), value: unitData.category !== 99 ? category.get(unitData.category).label : undefined },
        { label: l("floor"), value: floor },
        { label: l("rooms"), value: unitData.rooms },
        { label: l("valuequota"), value: unitData.realestate?.condominium ? unitData.valuequota : undefined },
        { label: l("ewid"), value: unitData.ewid },
        { label: l("otoid"), value: unitData.otoid },
        {
            label: t("screens.unit.appliances").toString(),
            value: "",
            to: unitAppliancesLink,
            onClick: onRowClick
        },
        {
            label: t("screens.unit.maintenances").toString(),
            value: "",
            to: unitMaintenancesLink,
            onClick: onRowClick
        }
    ];

    let displayTenantAccountBalanceRow = tenancyData.accountbalance ? true : false;

    const currentTenantTenancyStatus = getTenancyStatus(
        tenancyData.leasestart,
        tenancyData.leaseend,
        tenancyData.rentalsOutTenancys,
        tenancyData.rentalsInTenancys
    );
    const tenantDetails: Array<UnitTenantItem> = [
        {
            period: `${getDate(tenancyData.leasestart)} - ${getDate(tenancyData.leaseend) ?? ""}`,
            name: tenancyData.tenantname ?? "-",
            accountbalance: getCurrencyElement(tenancyData.accountbalance),
            status: currentTenantTenancyStatus,
            classNameRow: "font-weight-bold",
            to:
                (authStore.user?.role === Role.MANAGER || authStore.user?.role === Role.OWNER) &&
                navStore.hasOptionTenantBookingsAndFiles
                    ? selectRoute(Route.unitTenant, authStore.user?.role, {
                          realestateid: realestateid,
                          unitid: unitid,
                          tenantid: tenancyData.tenantid
                      })
                    : undefined
        }
    ];

    if (unitData.futuretenancies.length > 0) {
        unitData.futuretenancies.forEach((futuretenancy, index) => {
            const tenantName = `${futuretenancy.tenant?.person?.name1} ${
                futuretenancy.tenant?.person?.name2 !== null ? futuretenancy.tenant?.person?.name2 : ""
            }`.trim();

            const leaseStart = getDate(futuretenancy.leasestart);
            const leaseEnd = getDate(futuretenancy.leaseend);

            if (futuretenancy.tenant?.accountbalance) {
                displayTenantAccountBalanceRow = true;
            }

            const futureTenantTenancyStatus = getTenancyStatus(
                futuretenancy.leasestart,
                futuretenancy.leaseend,
                futuretenancy.rentalsOutTenancys,
                futuretenancy.rentalsInTenancys
            );

            tenantDetails.push({
                key: `futuretenantperson${index}`,
                period: `${leaseStart ?? ""} - ${leaseEnd ?? ""}`,
                name: tenantName,
                accountbalance: getCurrencyElement(futuretenancy.tenant?.accountbalance),
                status: futureTenantTenancyStatus
            });
        });
    }

    const tenantDetailsEasyContact: Array<SingleValueItem> = [];

    if (
        navStore.hasFeatureEasyContact &&
        authStore.user?.availableRoles.includes(Role.MANAGER) &&
        !tenancyData.vacancy
    ) {
        const easyContactTenantToken = btoa(`T=${tenancyData.tenantid}`);

        tenantDetailsEasyContact.push({
            value: navStore.isCondominium
                ? l("owner_submit_easycontact_report")
                : l("tenant_submit_easycontact_report"),
            to: `${NetworkConfig.proxy}/ec/start?t=${easyContactTenantToken}`
        });
    }

    const tenancyPersons: Array<UnitItem> = tenancyData.tenancypersons.map((tenancyPerson, i) => {
        let role = tenancypersonroles.get(tenancyPerson.role).label;

        if (navStore.isCondominium) {
            if (tenancyPerson.role === 1) {
                role = t("screens.unit.owner");
            }

            if (tenancyPerson.role === 2) {
                role = t("screens.unit.ownerperson");
            }
        }

        const name = `${tenancyPerson.person?.name1 ?? ""} ${tenancyPerson.person?.name2 ?? ""}`;

        let link = undefined;

        if (tenancyPerson.person && tenancyPerson.person.id) {
            link = selectRoute(Route.unitPerson, authStore.user?.role, {
                realestateid: realestateid,
                unitid: unitid,
                personid: tenancyPerson.person?.id
            });
        }

        return { key: i, label: role, value: name.trim().length === 0 ? "-" : name, to: link };
    });

    const rentDetails: Array<UnitItem> = [
        { label: l("validfrom"), value: getDate(tenancyData.validfrom) },
        {
            label: l("netrent"),
            value: navStore.isCondominium
                ? undefined
                : tenancyData.netrent !== 0.0
                ? getCurrencyElement(tenancyData.netrent)
                : undefined
        },
        {
            label: l("paymentonaccount"),
            value: navStore.isCondominium
                ? undefined
                : tenancyData.paymentonaccount !== 0.0
                ? getCurrencyElement(tenancyData.paymentonaccount)
                : undefined
        },
        {
            label: l("flatratefee"),
            value: navStore.isCondominium
                ? undefined
                : tenancyData.flatratefee !== 0.0
                ? getCurrencyElement(tenancyData.flatratefee)
                : undefined
        },
        { label: l("vat"), value: tenancyData.vat },
        {
            label: navStore.isCondominium ? l("budgetrate") : l("grossrent"),
            value: tenancyData.grossrent !== 0.0 ? getCurrencyElement(tenancyData.grossrent) : undefined
        },
        { label: l("maturities"), value: tenancyData.maturities && convert.dec2months(tenancyData.maturities, true) },
        { label: l("minimumrentdate"), value: getDate(tenancyData.minimumrentdate) },
        {
            label: l("noticemonths"),
            value: tenancyData.noticemonths && convert.dec2months(tenancyData.noticemonths, true)
        },
        { label: l("noticeperiodowner"), value: getNoticePeriod(tenancyData.noticeperiodowner || "", erpTypeUser) },
        { label: l("noticeperiodtenant"), value: getNoticePeriod(tenancyData.noticeperiodtenant || "", erpTypeUser) },
        { label: l("vattaxed"), value: tenancyData.vattaxed ? tenancyData.vat : "" },
        {
            label: l("interestrateindex"),
            value: tenancyData.interestrateindex ? tenancyData.interestrateindex + "%" : ""
        }
    ];

    const consumerpriceindex = tenancyData.consumerpriceindex ? getDate(tenancyData.consumerpriceindex) : null;
    const costincreaseindex = tenancyData.consumerpriceindex ? getDate(tenancyData.costincreaseindex) : null;
    const rentincreasereservetext = tenancyData.rentincreasereservetext;

    let rentBaseDetails: Array<UnitItem> = [];

    if (consumerpriceindex !== null || costincreaseindex !== null || rentincreasereservetext !== null) {
        rentBaseDetails = [
            { label: l("consumerpriceindex"), value: consumerpriceindex },
            { label: l("costincreaseindex"), value: costincreaseindex },
            {
                label: l("rentincreasereserveamount"),
                value: getCurrencyElement(tenancyData.rentincreasereserveamount)
            },
            { label: l("rentincreasereservetext"), value: tenancyData.rentincreasereservetext }
        ];
    }

    const unitDetailsList = (
        <UI.Card title={t("screens.unit.object").toString()} flex>
            {unitDetails.length > 0 ? (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={unitDetails}
                    renderItem={renderItem}
                    className={styles.UnitDetailsList}
                />
            ) : undefined}
            {unitDetailsEasyContact.length > 0 ? (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={unitDetailsEasyContact}
                    renderItem={renderSingleValueItem}
                />
            ) : undefined}
        </UI.Card>
    );

    const tenancyChangeIconInformation = getTenancyChangeInformation(unitData);
    let toolTip = undefined;
    let tenancyChangeIcon = undefined;

    if (tenancyChangeIconInformation) {
        toolTip = (
            <UI.Tooltip id={`${unitData.id}-tooltip`}>
                {t(tenancyChangeIconInformation.descriptionTranslationPath)}
            </UI.Tooltip>
        );
        tenancyChangeIcon = (
            <UI.OverlayTrigger key={`${unitData.id}-tooltip`} placement="right" overlay={toolTip}>
                <UI.BootstrapButton variant="link" className={styles.TenancyChangeButton}>
                    <img
                        src={tenancyChangeIconInformation.iconFilePath}
                        alt={t(tenancyChangeIconInformation.descriptionTranslationPath).toString()}
                        className={styles.TenancyChangeIcon}
                    />
                </UI.BootstrapButton>
            </UI.OverlayTrigger>
        );
    }

    const tenantDetailsList = (
        <UI.Card
            title={navStore.isCondominium ? t("screens.unit.owner").toString() : t("screens.unit.tenant").toString()}
            additionalIconElement={tenancyChangeIconInformation ? tenancyChangeIcon : undefined}
            flex
        >
            {tenantDetails.length > 0 ? (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={tenantDetails}
                    renderItem={renderTenantItem(displayTenantAccountBalanceRow)}
                    renderHeader={renderTenantListHeader(displayTenantAccountBalanceRow)}
                />
            ) : undefined}
            {tenantDetailsEasyContact.length > 0 ? (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={tenantDetailsEasyContact}
                    renderItem={renderSingleValueItem}
                />
            ) : undefined}
        </UI.Card>
    );

    const tenancyPersonsList =
        tenancyPersons.length !== 0 ? (
            <UI.Card
                title={
                    navStore.isCondominium
                        ? t("screens.unit.ownerpersons").toString()
                        : t("screens.unit.tenancypersons").toString()
                }
                flex
            >
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={tenancyPersons}
                    renderItem={renderItem}
                />
            </UI.Card>
        ) : undefined;

    const rentDetailsList = (
        <UI.Card
            title={navStore.isCondominium ? t("screens.unit.budget").toString() : t("screens.unit.rent").toString()}
            flex
        >
            {rentDetails.length === 0 ? (
                <p>{t("error.emptydata")}</p>
            ) : (
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={rentDetails}
                    renderItem={renderItem}
                />
            )}
        </UI.Card>
    );

    const rentBaseDetailList =
        navStore.isCondominium === true || rentBaseDetails.length === 0 ? undefined : (
            <UI.Card title={t("screens.unit.rent_base").toString()} flex>
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={rentBaseDetails}
                    renderItem={renderItem}
                />
            </UI.Card>
        );

    const unitFileList =
        sortedUnitFiles.length === 0 ? undefined : (
            <UI.Card title={t("screens.realestate.documents.title").toString()} flex>
                <UnitFileList unitFiles={sortedUnitFiles} />
            </UI.Card>
        );

    return (
        <ScreenWithTiles title={unitData?.name ?? ""} screenId={ScreenType.UNIT} realestateid={realestateid}>
            {unitDetailsList}
            {tenantDetailsList}
            {tenancyPersonsList}
            {rentDetailsList}
            {rentBaseDetailList}
            {unitFileList}
        </ScreenWithTiles>
    );
};
export const Unit = Sentry.withProfiler(withRouter(observer(UnitBase)));
