import { gql } from "@apollo/client";

export const REALESTATE_DETAILS_ALL_INVOICES = gql`
    fragment realestate_details_all_invoices on common_realestates {
        id
        name
        number
        consolidation
        houses(limit: 1) {
            zip
            city
        }
    }
`;

export const UNIT_DETAILS = gql`
    fragment unit_details on common_units {
        id
        number
        name
        houseid
    }
`;

export const REALESTATE_DETAILS = gql`
    fragment invoice_realestate_details on common_realestates {
        id
        name
        number
        consolidation
        houses(limit: 1) {
            zip
            city
        }
        thumbnail: v_realestatefiles(
            where: { type: { _eq: 311 } }
            order_by: { filedate: desc, fileid: desc }
            distinct_on: fileid
            limit: 1
        ) {
            fileid
        }
    }
`;

export const USER_DETAIL = gql`
    fragment invoiceuser_details on ums_users {
        id
        name1
        name2
    }
`;

export const PAYMENTACCOUNT_DETAILS = gql`
    fragment paymentaccount_details on cred_paymentaccounts {
        id
        iban
        name
        type
    }
`;

export const V_TICKET_DETAILS = gql`
    fragment v_ticket_details on cred_v_tickets {
        incidentid
        realestateid
        unitid
        date
        date_long
        number
        title
    }
`;

export const INVOICE_FILES_DETAILS = gql`
    fragment invoicefiles_details on file_v_invoicefiles {
        invoicefileid: id
        fileid
        type
    }
`;

export const V_ORDER_DETAILS = gql`
    ${V_TICKET_DETAILS}
    fragment v_order_details on cred_v_orders {
        orderid
        realestateid
        date
        date_long
        number
        title
        instruction
        contractorid
        name1
        name2
        contractortype
        ticket: incident {
            ...v_ticket_details
        }
    }
`;

export const V_CREDITOR_DETAILS = gql`
    ${PAYMENTACCOUNT_DETAILS}
    fragment v_creditor_details on cred_v_creditors {
        creditorid
        creditorname
        personid
        email
        gender
        name1
        name2
        phonebusiness
        mobile
        person_origin
        zip
        city
        street
        housenumber
        paymentaccounts {
            ...paymentaccount_details
        }
    }
`;

export const INVOICE_DETAILS_ALL_INVOICES = gql`
    ${REALESTATE_DETAILS_ALL_INVOICES}
    ${USER_DETAIL}
    fragment invoice_details_all_invoices on cred_invoices {
        id
        type
        number
        date
        duedate
        state
        invoicenumber
        amount
        creditor {
            id
            name
            personid
        }
        realestate {
            ...realestate_details_all_invoices
        }
        insertuserinfo {
            ...invoiceuser_details
        }
        workflowusers {
            id
            role
            user {
                ...invoiceuser_details
            }
        }
    }
`;

export const INVOICE_DETAILS = gql`
    ${REALESTATE_DETAILS}
    ${UNIT_DETAILS}
    ${USER_DETAIL}
    ${V_CREDITOR_DETAILS}
    ${PAYMENTACCOUNT_DETAILS}
    ${V_TICKET_DETAILS}
    ${V_ORDER_DETAILS}
    ${INVOICE_FILES_DETAILS}
    fragment invoice_details on cred_invoices {
        id
        type
        number
        date
        duedate
        paiddate
        state
        invoicenumber
        amount
        paymentaccountid
        payoutaccountid
        paymentinfo
        workflowinstance
        paymentaccount {
            ...paymentaccount_details
        }
        creditor: v_creditor {
            ...v_creditor_details
        }
        ticket: v_incident {
            ...v_ticket_details
        }
        order: v_order {
            ...v_order_details
        }
        realestate {
            ...invoice_realestate_details
        }
        unit {
            ...unit_details
        }
        insertuserinfo {
            ...invoiceuser_details
        }
        workflowusers(order_by: { role: asc }) {
            id
            role
            signaturestamp
            user {
                ...invoiceuser_details
            }
        }
        invoicefiles: v_invoicefiles {
            ...invoicefiles_details
        }
    }
`;

export const GET_ALL_INVOICES = gql`
    ${INVOICE_DETAILS_ALL_INVOICES}
    query GetAllInvoices($limit: Int = 99999, $offset: Int = 0) {
        cred_invoices(order_by: { number: desc }, limit: $limit, offset: $offset) {
            ...invoice_details_all_invoices
        }
    }
`;

export const GET_INVOICE_BY_NUMBER = gql`
    ${INVOICE_DETAILS}
    query GetInvoiceByNumber($invoiceNumber: Int) {
        cred_invoices(where: { number: { _eq: $invoiceNumber } }, limit: 1) {
            ...invoice_details
        }
    }
`;

export const GET_INVOICE_NUMBER_BY_ID = gql`
    ${INVOICE_DETAILS}
    query GetInvoiceNumberById($id: uuid!) {
        cred_invoices(where: { id: { _eq: $id } }, limit: 1) {
            id
            number
        }
    }
`;

export const GET_CREDITOR_DATA = gql`
    ${V_CREDITOR_DETAILS}
    query GetCreditorData {
        creditors: cred_v_creditors {
            ...v_creditor_details
        }
    }
`;

export const GET_CREDITORS_WITH_QUERY = gql`
    ${V_CREDITOR_DETAILS}
    query GetCreditorsWithQuery($searchQuery: String) {
        creditors: cred_v_creditors(
            where: {
                _or: [
                    { creditorname: { _ilike: $searchQuery } }
                    { name1: { _ilike: $searchQuery } }
                    { name2: { _ilike: $searchQuery } }
                    { mobile: { _ilike: $searchQuery } }
                    { phonebusiness: { _ilike: $searchQuery } }
                    { email: { _ilike: $searchQuery } }
                    { city: { _ilike: $searchQuery } }
                    { zip: { _ilike: $searchQuery } }
                    { street: { _ilike: $searchQuery } }
                ]
            }
        ) {
            ...v_creditor_details
        }
    }
`;

export const GET_TICKET_DATA = gql`
    ${V_TICKET_DETAILS}
    query GetTicketData {
        tickets: cred_v_tickets(order_by: { number: desc }) {
            ...v_ticket_details
        }
    }
`;

export const GET_TICKET_DATA_BY_REALESTATEID = gql`
    ${V_TICKET_DETAILS}
    query GetTicketDataByRealestateId($realestateid: uuid) {
        tickets: cred_v_tickets(order_by: { number: desc }, where: { realestateid: { _eq: $realestateid } }) {
            ...v_ticket_details
        }
    }
`;

export const GET_TICKETS_WITH_QUERY = gql`
    ${V_TICKET_DETAILS}
    query GetTicketsWithQuery($searchQuery: String) {
        tickets: cred_v_tickets(
            where: {
                _or: [
                    { date: { _ilike: $searchQuery } }
                    { date_long: { _ilike: $searchQuery } }
                    { number: { _ilike: $searchQuery } }
                    { title: { _ilike: $searchQuery } }
                ]
            }
        ) {
            ...v_ticket_details
        }
    }
`;

export const GET_ORDER_DATA = gql`
    ${V_ORDER_DETAILS}
    query GetOrderData {
        orders: cred_v_orders(order_by: { number: desc }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDER_DATA_BY_REALESTATEID = gql`
    ${V_ORDER_DETAILS}
    query GetOrderDataByRealestateId($realestateid: uuid) {
        orders: cred_v_orders(order_by: { number: desc }, where: { realestateid: { _eq: $realestateid } }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDER_DATA_BY_INCIDENT_ID = gql`
    ${V_ORDER_DETAILS}
    query GetOrderDataByIncidentId($incidentid: uuid) {
        orders: cred_v_orders(order_by: { number: desc }, where: { incidentid: { _eq: $incidentid } }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDER_DATA_BY_CREDITOR_ID = gql`
    ${V_ORDER_DETAILS}
    query GetOrderDataByCreditorId($creditorid: uuid) {
        orders: cred_v_orders(order_by: { number: desc }, where: { contractorid: { _eq: $creditorid } }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDERS_WITH_QUERY = gql`
    ${V_ORDER_DETAILS}
    query GetOrdersWithQuery($searchQuery: String) {
        orders: cred_v_orders(
            where: {
                _or: [
                    { date: { _ilike: $searchQuery } }
                    { date_long: { _ilike: $searchQuery } }
                    { number: { _ilike: $searchQuery } }
                    { instruction: { _ilike: $searchQuery } }
                    { name1: { _ilike: $searchQuery } }
                ]
            }
        ) {
            ...v_order_details
        }
    }
`;

export const GET_ACTIVE_MANAGER_USERS = gql`
    query GetActiveManagerUsers {
        ums_v_customer_users(
            where: {
                activated: { _eq: true }
                system: { _eq: false }
                blocked: { _eq: false }
                roles: { role: { _eq: 30 } }
            }
        ) {
            userid
            name
        }
    }
`;

/* MUTATIONS */
export const UPSERT_INVOICE = gql`
    mutation UpsertInvoice($invoice: cred_invoices_insert_input!) {
        upserted_invoice_data: insert_cred_invoices_one(
            object: $invoice
            on_conflict: {
                constraint: PK_invoices
                update_columns: [
                    amount
                    creditorid
                    creditorinfo
                    date
                    duedate
                    incidentid
                    insertuserid
                    invoiceinfo
                    invoicenumber
                    orderid
                    origin
                    paiddate
                    paymentaccountid
                    paymentinfo
                    payoutaccountid
                    realestateid
                    state
                    type
                    unitid
                    workflowinstance
                ]
            }
        ) {
            id
        }
    }
`;

export const INSERT_WORKFLOW_USER = gql`
    mutation InsertWorkflowUser($workflowuser: cred_workflowusers_insert_input!) {
        insert_cred_workflowusers_one(object: $workflowuser) {
            id
            role
            signaturestamp
            user {
                id
                name1
                name2
            }
        }
    }
`;

export const UPDATE_USER_ID_FOR_WORKFLOW_USER = gql`
    mutation UpdateUserIdForWorkflowUser($invoiceid: uuid!, $role: Int!, $userid: uuid!) {
        update_cred_workflowusers(
            where: { invoiceid: { _eq: $invoiceid }, role: { _eq: $role } }
            _set: { userid: $userid }
        ) {
            affected_rows
            returning {
                user {
                    id
                    name1
                    name2
                }
            }
        }
    }
`;

/* SUBSCRIPTIONS */
export const SUBSCRIBE_TO_INVOICE_WORKFLOWUSER_UPDATES = gql`
    ${USER_DETAIL}
    subscription SubscribeToInvoiceWorkflowUserUpdates($invoiceid: uuid!) {
        workflowusers: cred_workflowusers(where: { invoiceid: { _eq: $invoiceid } }, order_by: { role: asc }) {
            id
            role
            signaturestamp
            user {
                ...invoiceuser_details
            }
        }
    }
`;

export const SUBSCRIBE_TO_INVOICE_STATE_UPDATE = gql`
    subscription SubscribeToInvoiceStateUpdate($invoiceid: uuid!) {
        invoice: cred_invoices(where: { id: { _eq: $invoiceid } }) {
            id
            state
        }
    }
`;
